import Table from '@/components/common/table/Table';
import { MonthlyreportHeader } from '@/components/table-headers/drivers/monthlyReport/MonthlyreportHeader';
import { IFilters } from '@/interfaces/Fetch';
import { MonthlyReportsData } from '@/interfaces/MonthlyReport';
import {
  useGetMonthlyReportQuery,
  useSendMonthlyReportMailMutation,
} from '@/services/drivers/monthlyReport';
import { faMailBulk } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ColumnDef, TableState } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

export const MonthlyReportCard = ({ driverId }: MonthlyReportCardProps) => {
  const [state, setState] = useState<Partial<TableState> & IFilters>({
    pagination: {
      pageIndex: 0,
      pageSize: 10,
    },
    globalFilter: '',
    sorting: [
      {
        id: 'created_at',
        desc: true,
      },
    ],
  });

  const { data, isLoading, isFetching } = useGetMonthlyReportQuery([
    driverId,
    {
      ...state.pagination,
      query: state.globalFilter,
      ...(state.sorting?.[0] && {
        sortBy: state.sorting[0].id,
        sortDirection: state.sorting[0].desc ? 'desc' : 'asc',
      }),
    },
  ]);

  const [sendMonthlyReportMailAsync] = useSendMonthlyReportMailMutation();

  const handleSendEmail = async (
    driverId: string,
    month: number,
    year: number
  ) => {
    try {
      toast.promise(
        sendMonthlyReportMailAsync({ driverId, month, year }).unwrap(),
        {
          success: 'Email sent successfully',
          pending: 'Sending email...',
          error: 'Error sending email',
        }
      );
    } catch (error) {
      console.error(error);
    }
  };

  const columns = useMemo<ColumnDef<MonthlyReportsData>[]>(
    () => [
      {
        header: 'Month Year',
        footer: props => props.column.id,
        accessorFn: ({ currentDate }) => currentDate,
        id: 'currentDate',
        cell: info => info.getValue(),
      },
      {
        header: '# Completed Rides',
        footer: props => props.column.id,
        accessorFn: ({ nbRideCompleted }) => nbRideCompleted,
        id: 'nbRideCompleted',
        cell: info => info.getValue(),
      },
      {
        header: 'Driver earnings',
        footer: props => props.column.id,
        accessorFn: ({ totalGains }) => <>{(totalGains / 100).toFixed(2)}€</>,
        id: 'totalGains',
        cell: info => info.getValue(),
      },
      {
        header: 'Report Link',
        footer: props => props.column.id,
        accessorFn: ({ pdfLink }) => (
          <Link to={pdfLink} target="_blank">
            Report Link
          </Link>
        ),
        id: 'pdfLink',
        cell: info => info.getValue(),
      },
      {
        header: 'Send Mail',
        footer: props => props.column.id,
        accessorFn: ({ driverId, month, year }) => (
          <button className="btn btn-primary btn-sm">
            <FontAwesomeIcon
              onClick={() => handleSendEmail(driverId, month, year)}
              icon={faMailBulk}
            />
          </button>
        ),
        id: 'sendMail',
        cell: info => info.getValue(),
      },
    ],
    []
  );

  return (
    <Card className="pb-3">
      <Table
        HeaderComponent={MonthlyreportHeader}
        columns={columns}
        state={state}
        onStateChange={setState}
        data={data?.data}
        meta={data?.meta}
        loading={isLoading || isFetching}
      />
    </Card>
  );
};

type MonthlyReportCardProps = {
  driverId: string;
};
