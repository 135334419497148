import ErrorComponent from '@/components/common/ErrorComponent';
import Loader from '@/components/common/Loader';
import SoftBadge from '@/components/common/SoftBadge';
import PageHeader from '@/components/common/header/PageHeader';
import {
  getColorFromGovStatusType,
  getReelGovStatusName,
} from '@/helpers/UserHelpers';
import { ICardDetailsProps } from '@/interfaces/Card';
import IGovernance from '@/interfaces/Governance';
import { useGetGovernanceQuery } from '@/services/governances';
import { DateTime } from 'luxon';
import { FC, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';

const GovernancePageHeaderCard: FC<ICardDetailsProps<IGovernance>> = ({
  id,
  callBack,
}) => {
  const { data, isLoading, isError, error } = useGetGovernanceQuery(id);

  useEffect(() => {
    data && callBack?.(data);
  }, [data]);

  if (isLoading) {
    return <Loader absolute />;
  } else if (isError) {
    return <ErrorComponent error={error} absolute returnButton />;
  } else if (!data) {
    return (
      <ErrorComponent error={{ status: 404, data: {} }} absolute returnButton />
    );
  }

  return (
    <Row className="g-2">
      <Col xs={12}>
        {/* Page Header */}
        <PageHeader title={`Coupon Details: ${data.title} / ${data.titleEn} (EN)`}>
          <p className="fs--1 mt-1">
            {DateTime.fromISO(data?.createdAt).toFormat(
              "dd LLL yyyy 'at' HH:mm"
            )}
          </p>
          <div>
            <strong className="me-2">Status : </strong>
            <SoftBadge
              bg={getColorFromGovStatusType(data.status)}
              className={'my-2 text-xl-center'}
            >
              {getReelGovStatusName(data.status)}
            </SoftBadge>
          </div>
        </PageHeader>
      </Col>
    </Row>
  );
};

export default GovernancePageHeaderCard;
