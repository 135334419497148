import api from './api';

export const weeklyEventApi = api.injectEndpoints({
  endpoints: build => ({
    getWeeklyEvents: build.query({
      query: () => '/weekly-events',
      providesTags: ['WeeklyEvent'],
    }),
    getWeeklyEvent: build.query({
      query: id => `/weekly-events/${id}`,
      providesTags: ['WeeklyEvent'],
    }),
    createWeeklyEvent: build.mutation({
      query: body => ({
        url: '/weekly-events',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['WeeklyEvent'],
    }),
    updateWeeklyEvent: build.mutation({
      query: ({ id, body }) => ({
        url: `/weekly-events/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['WeeklyEvent'],
    }),
    deleteWeeklyEvent: build.mutation({
      query: id => ({
        url: `/weekly-events/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['WeeklyEvent'],
    }),
    archiveWeeklyEvent: build.mutation({
      query: id => ({
        url: `/weekly-events/${id}/archive`,
        method: 'PATCH',
      }),
      invalidatesTags: ['WeeklyEvent'],
    }),
  }),
});

export const {
  useGetWeeklyEventsQuery,
  useGetWeeklyEventQuery,
  useCreateWeeklyEventMutation,
  useUpdateWeeklyEventMutation,
  useDeleteWeeklyEventMutation,
  useArchiveWeeklyEventMutation,
} = weeklyEventApi;
