import IconButton from '@/components/common/IconButton';
import { ITableHeaderComponentProps } from '@/interfaces/Table';
import { faFileExport, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TableState } from '@tanstack/react-table';
import { FC } from 'react';
import { Button, Col, Form, FormControl, InputGroup, Row } from 'react-bootstrap';

const BookingHeader: FC<ITableHeaderComponentProps> = ({
  onQueryChange,
  onStateChange,
}) => {
  return (
    <div className="d-lg-flex justify-content-between">
      <Row className="flex-between-center gy-2 px-x1">
        <Col xs="auto" className="pe-0">
          <h6 className="mb-0">Bookings</h6>
        </Col>
        <Col xs="auto">
          <InputGroup className="position-relative input-search-width">
            <FormControl
              size="sm"
              id="search"
              type="search"
              className="shadow-none"
              placeholder="Rechercher"
              onChange={e => onQueryChange(e.target.value)}
            />
            <Button
              size="sm"
              variant="outline-secondary"
              className="border-300 hover-border-secondary"
            >
              <FontAwesomeIcon icon={faSearch} className="fs--1" />
            </Button>
          </InputGroup>
        </Col>
        <Col xs="auto">
          <Form.Group controlId="timeSelect">
            <Form.Control
              as="select"
              onChange={e =>
                onStateChange?.((old: Partial<TableState>) => ({
                  ...old,
                  pagination: {
                    pageSize: old.pagination?.pageSize ?? 15,
                    pageIndex: old.pagination?.pageIndex ?? 0,
                  },
                  status: e.target.value,
                }))
              }
            >
              <option value="ride">Filter by Ride Date</option>
              <option value="all">Filter by Created Date</option>
              <option value="upcoming">Upcoming</option>
            </Form.Control>
          </Form.Group>
        </Col>
      </Row>

      <div className="d-flex align-items-center justify-content-between justify-content-lg-end px-x1">
        <div id="orders-actions">
          <a
            href={`${process.env.REACT_APP_API_URI}/export/bookings`}
            rel="noreferrer"
          >
            <IconButton
              variant="nolina-default"
              size="sm"
              icon={faFileExport}
              transform="shrink-3"
              style={{ marginLeft: '10px' }}
            >
              <span className="d-none d-sm-inline-block ms-1">Export</span>
            </IconButton>
          </a>
        </div>
      </div>
    </div>
  );
};

export default BookingHeader;
