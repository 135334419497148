import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import IconButton from '@/components/common/IconButton';
import { useNavigate, useParams } from 'react-router-dom';
import Flex from '@/components/common/Flex';
import { Controller, SubmitErrorHandler, useForm } from 'react-hook-form';
import useLoadOptions from '@/hooks/useLoadOptions';
import Loader from '@/components/common/Loader';
import Select, {
  AsyncSelectComponent,
  CustomGoogleMenu,
} from '@/components/common/Select';
import classNames from 'classnames';
import { useEffect } from 'react';
import {
  EVENT_TYPE_OPTIONS,
  INDICATOR_EVENT_OPTIONS,
  IWeeklyEventCreate,
} from '@/interfaces/WeeklyEvent';
import { DateTime } from 'luxon';
import { useGetWeeklyEventQuery, useUpdateWeeklyEventMutation } from '@/services/weeklyEvent';

const WeeklyEventEditView = () => {
  const navigation = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { data, isLoading } = useGetWeeklyEventQuery(id!, { skip: !id });

  const {
    control,
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<DeepPartial<IWeeklyEventCreate>>();

  const { loadPlacesAutocomplete } = useLoadOptions();

  const [updateCompanyMutation] = useUpdateWeeklyEventMutation();

  const onSubmit = async (data: DeepPartial<IWeeklyEventCreate>) => {
    console.log(data)
    await updateCompanyMutation({
      id: id!,
      body: {
        ...data,
        type: data?.type?.value,
        indicator: data?.indicator?.value,
        address: data.address?.place_id,
      },
    }).unwrap();
    navigation(-1);
  };

  const onError: SubmitErrorHandler<DeepPartial<IWeeklyEventCreate>> = error =>
    console.log(error);

  useEffect(() => {
    if (data) {
      reset({
        name: data.name,
        endDate: DateTime.fromISO(data.endDate).toFormat("yyyy-MM-dd'T'HH:mm"),
        startDate: DateTime.fromISO(data.startDate).toFormat("yyyy-MM-dd'T'HH:mm"),
        type: EVENT_TYPE_OPTIONS.find(e => e.value === data.type),
        indicator: INDICATOR_EVENT_OPTIONS.find(e => e.value === data.indicator),
        nbOfAttendees: data.nbAttendees,
        address: {
          place_id: data.address?.placeId,
          name: data.address?.name,
        },
      });
    }
  }, [data, reset]);

  if (isLoading) {
    return <Loader absolute />;
  }

  return (
    <>
      <Card className="mb-3">
        <Card.Header className="d-flex flex-between-center">
          <IconButton
            onClick={() => navigation(-1)}
            variant="nolina-default"
            size="sm"
            icon={faArrowLeft}
          />
          <Flex>
            <div
              className="bg-300 mx-3 d-xl-none"
              style={{ width: '1px', height: '29px' }}
            ></div>
          </Flex>
        </Card.Header>
      </Card>

      <Form onSubmit={handleSubmit(onSubmit, onError)}>
        <Card>
          <Card.Body className="bg-light">
            <h4 className="mb-0">Edit a weekly event</h4>
            <h6 className="mt-1 text-secondary">
              The required fields are marked with{' '}
              <span className="text-danger">*</span>.
            </h6>
            <Row className="gx-3">
              <Col>
                <Row className="gx-3">
                  <Col lg={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        Name <span className="text-danger">*</span> :
                      </Form.Label>
                      <Form.Control
                        placeholder="ex : NOEL10"
                        type="text"
                        {...register('name', {
                          required: 'This field is required.',
                          minLength: {
                            value: 2,
                            message:
                              'This field must contain at least 2 characters.',
                          },
                          maxLength: {
                            value: 255,
                            message:
                              'This field must contain at most 255 characters.',
                          },
                        })}
                        isInvalid={!!errors.name}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.name?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>

                  <Col lg={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        Type <span className="text-danger">*</span>
                      </Form.Label>
                      <Controller
                        control={control}
                        name="type"
                        render={({ field }) => (
                          <Select
                            options={EVENT_TYPE_OPTIONS}
                            value={field.value}
                            onChange={field.onChange}
                          />
                        )}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.type?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>

                  <Col lg={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        Start At <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="datetime-local"
                        {...register('startDate', {
                          validate: value => {
                            if (DateTime.fromISO(value!) <= DateTime.now()) {
                              return 'La date de début doit être supérieure à la date actuelle';
                            }
                          },
                          required: 'La date de début est obligatoire',
                        })}
                        isInvalid={!!errors.startDate?.message}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.startDate?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>

                  <Col lg={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        End At <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="datetime-local"
                        {...register('endDate', {
                          validate: value => {
                            if (DateTime.fromISO(value!) <= DateTime.now()) {
                              return 'La date de début doit être supérieure à la date actuelle';
                            }
                          },
                          required: 'La date de début est obligatoire',
                        })}
                        isInvalid={!!errors.endDate?.message}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.endDate?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>

                  <Col lg={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        Adresse<span className="text-danger">*</span> :
                      </Form.Label>
                      <Controller
                        control={control}
                        name="address"
                        rules={{
                          required: "L'adresse est obligatoire",
                        }}
                        render={({ field }) => (
                          <AsyncSelectComponent
                            loadOptions={loadPlacesAutocomplete}
                            value={field.value}
                            closeMenuOnSelect
                            isClearable
                            onChange={field.onChange}
                            components={{
                              Menu: CustomGoogleMenu,
                            }}
                            getOptionLabel={option => option.name}
                            getOptionValue={option => option.place_id}
                          />
                        )}
                      />
                      <Form.Control.Feedback
                        type="invalid"
                        className={classNames({
                          'd-block': errors.address,
                        })}
                      >
                        {errors.address?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>

                  <Col lg={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        Nb of Attendees <span className="text-danger">*</span> :
                      </Form.Label>
                      <Form.Control
                        type="number"
                        min={0}
                        {...register('nbOfAttendees', {
                          required: 'This field is required.',
                        })}
                        isInvalid={!!errors.nbOfAttendees}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.nbOfAttendees?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>

                  <Col lg={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        Indicator <span className="text-danger">*</span> :
                      </Form.Label>
                      <Controller
                        control={control}
                        name="indicator"
                        render={({ field }) => (
                          <Select
                            options={INDICATOR_EVENT_OPTIONS}
                            value={field.value}
                            onChange={field.onChange}
                          />
                        )}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.indicator?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>

                <Button
                  type="submit"
                  variant="success"
                  disabled={isLoading}
                  className="mt-3"
                >
                  Update the weekly event
                </Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Form>
    </>
  );
};

export default WeeklyEventEditView;
