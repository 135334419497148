import { IFilters, IPaginateResponse } from '@/interfaces/Fetch';
import api from './api';
import { IClientCompany, IClientCompanyCreate } from '@/interfaces/ClientCompany';
import { createFormDataRequest } from '@/helpers/utils';

export const clientCompanyApi = api.injectEndpoints({
  endpoints: build => ({
    getClientCompanies: build.query<IPaginateResponse<IClientCompany>, IFilters>({
      query: (data) => {
        const params = createFormDataRequest(data);
        return { url: '/client-company', params };
      },
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: 'ClientCompany' as const, id })),
              { type: 'ClientCompany', id: 'LIST' },
            ]
          : [{ type: 'ClientCompany', id: 'LIST' }],
    }),
    getClientCompany: build.query<IClientCompany, string>({
      query: (id) => `/client-company/${id}`,
      providesTags: (_, error, id) => [{ type: 'ClientCompany', id }],
    }),
    createClientCompany: build.mutation<IClientCompany, Partial<IClientCompanyCreate>>({
      query: (body) => ({
        url: '/client-company',
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'ClientCompany', id: 'LIST' }],
    }),
    updateClientCompany: build.mutation<IClientCompany, { id: string; body: Partial<IClientCompany> }>({
      query: ({ id, body }) => ({
        url: `/client-company/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'ClientCompany', id }],
    }),
    deleteClientCompany: build.mutation<{ success: boolean }, string>({
      query: (id) => ({
        url: `/client-company/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, id) => [{ type: 'ClientCompany', id }],
    }),
  }),
});

export const {
  useGetClientCompaniesQuery,
  useLazyGetClientCompaniesQuery,
  useGetClientCompanyQuery,
  useCreateClientCompanyMutation,
  useUpdateClientCompanyMutation,
  useDeleteClientCompanyMutation
} = clientCompanyApi;
