import Driver from '@/interfaces/Driver';
import { IFilters } from '@/interfaces/Fetch';
import { ISelectOption } from '@/interfaces/Select';
import IFile from '../IFile';
import UserCoupon from './Coupon';
import Company from '../Company';
import { IClientCompany } from '../ClientCompany';

export type UserRoles = 'superadmin' | 'admin' | 'user';

export const UserTypeConst_ = ['user', 'driver'] as const;

export type UserType = (typeof UserTypeConst_)[number];

export type UserLoginType = 'email' | 'phone' | 'apple' | 'google';

export const USER_ROLES: ISelectOption<UserRoles>[] = [
  { value: 'superadmin', label: 'Super administrateur' },
  { value: 'admin', label: 'Administrateur' },
  { value: 'user', label: 'Utilisateur' },
];

export type GenderType = 'male' | 'female';

export const GENDERS: ISelectOption<GenderType>[] = [
  {
    value: 'female',
    label: 'Femme',
  },
  {
    value: 'male',
    label: 'Homme',
  },
];

export const USER_TYPES_OPTIONS: ISelectOption<UserType>[] = [
  {
    value: 'user',
    label: 'User',
  },
  {
    value: 'driver',
    label: 'Driver',
  },
];

export const ACCESS_TYPES = [{
  value: true,
  label: 'Yes',
}, {
  value: false,
  label: 'No',
}]

export default interface IUser {
  id: string;
  file?: string;
  driverProfile: Driver | null;
  avatar: IFile;
  referralCode: string;
  firstname: string;
  lastname: string;
  fullname: string;
  gender: GenderType | null;
  email: string;
  phone: string;
  birthdate: string;
  role: UserRoles;
  appleId: string | null;
  stripeId: string | null;
  lastLoginAt: string | null;
  lastLoginType: UserLoginType | null;
  appVersion: string;
  appOS: string;
  points: number;
  requestsCount: number;
  completedRidesCount: number;
  referredUsersCount: number;
  totalSpent: number;
  governanceVotesCount: number;
  cancelRate: number;
  averageRating: number;
  createdAt: string;
  updatedAt: string;
  referrer: IRefferedUser | null;
  firstCoupon: UserCoupon | null;
  hasAccessReservePlatform: boolean;
}

export interface IRefferedUser {
  id: string;
  referredDriverProfileId: string | null;
  referredUserId: string;
  referrerUserId: string | null;
  referrerDriverProfileId: string | null;
  createdAt: string;
  updatedAt: string;
  referrerDriverProfile: Driver;
  referrerUser: IUser | null;
}

export interface IUserForm
  extends Omit<
    IUser,
    'createdAt' | 'updatedAt' | 'id' | 'avatar' | 'gender' | 'role' | 'hasAccessReservePlatform'
  > {
  gender: ISelectOption<GenderType>;
  role: ISelectOption<UserRoles>;
  avatar: File | null;
  hasAccessReservePlatform: ISelectOption<boolean>;
  clientCompany: IClientCompany | null;
}

export interface IUserFormCSV
  extends Omit<IUser, 'role' | 'gender' | 'avatar'> {
  role: UserRoles;
  gender: GenderType;
  avatar: string | null;
}

export interface IUserFormSend
  extends Omit<IUserForm, 'role' | 'gender' | 'avatar' | 'hasAccessReservePlatform' | 'clientCompany'> {
  role: UserRoles;
  gender: GenderType | null;
  avatar: File | null;
  hasAccessReservePlatform: boolean;
  clientCompany: string | null;
}

export interface IUserFilters extends IFilters {
  roles?: UserRoles[];
}
