import { IFilters, IPaginateResponse } from '@/interfaces/Fetch';
import { createFormDataRequest } from '../helpers/utils';
import api from './api';
import Company, {
  CompanyFormCreateSend,
  CompanyFormUpdateSend,
} from '@/interfaces/Company';
import Driver, { CompanyDrivers } from '@/interfaces/Driver';

export const companiesApi = api.injectEndpoints({
  endpoints: build => ({
    getCompanies: build.query<IPaginateResponse<Company>, IFilters>({
      query: data => {
        const params = createFormDataRequest(data);
        return {
          url: '/companies',
          params,
        };
      },
      providesTags: (result, _error, filters) =>
        result
          ? [
              ...result.data.map(({ id }) => ({
                type: 'Companies' as const,
                id,
              })),
              { type: 'Companies', id: 'LIST', ...filters },
            ]
          : [],
    }),
    destroyCompany: build.mutation<void, string>({
      query: id => ({
        url: `/companies/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'Companies', id: 'LIST' }],
    }),
    getCompany: build.query<Company, string>({
      query: id => `/companies/${id}`,
      providesTags: (_result, _error, id) => [{ type: 'Companies', id }],
    }),
    getCompanyByTaxId: build.query<Company, string>({
      query: taxId => ({
        url: `/companies/tax-id/${taxId}`,
        method: 'GET',
      }),
      providesTags: (_result, _error, taxId) => [
        { type: 'Companies', id: `TAX_ID_${taxId}` },
      ],
    }),
    getCompanyDrivers: build.query<IPaginateResponse<CompanyDrivers>, { companyId: string; pageIndex?: number; pageSize?: number }>({
      query: ({ companyId, pageIndex = 0, pageSize = 15 }) => ({
        url: `/companies/${companyId}/drivers`,
        params: { pageIndex, pageSize },
      }),
      providesTags: (result, _error, { companyId }) =>
        result
          ? [
              ...result.data.map(driver => ({
                type: 'CompanyDrivers' as const,
                id: driver.id,
              })),
              { type: 'CompanyDrivers', id: `COMPANY_${companyId}` },
            ]
          : [],
    }),
    updateCompany: build.mutation<
      Company,
      [string, Partial<CompanyFormUpdateSend>]
    >({
      query: ([id, company]) => ({
        url: `/companies/${id}`,
        method: 'PUT',
        body: company,
      }),
      invalidatesTags: (result, _error, [id]) => [{ type: 'Companies', id }],
    }),
    createCompany: build.mutation<Company, CompanyFormCreateSend>({
      query: company => ({
        url: '/companies',
        method: 'POST',
        body: company,
      }),
      invalidatesTags: [{ type: 'Companies', id: 'LIST' }],
    }),
    sendReminder: build.mutation<void, string>({
      query: id => ({
        url: `/companies/${id}/reminder-fleet`,
        method: 'POST',
      }),
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetCompaniesQuery,
  useGetCompanyQuery,
  useUpdateCompanyMutation,
  useCreateCompanyMutation,
  useLazyGetCompaniesQuery,
  useGetCompanyDriversQuery,
  useDestroyCompanyMutation,
  useSendReminderMutation,
  useLazyGetCompanyByTaxIdQuery
} = companiesApi;
