import Modal from '@/components/common/Modal';
import {
  AsyncSelectComponent,
  CustomGoogleMenu,
} from '@/components/common/Select';
import { IModalMethods } from '@/interfaces/Modal';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import {
  ElementRef,
  ForwardRefRenderFunction,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import {
  Controller,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { ToastContentProps, toast } from 'react-toastify';
import useLoadOptions from '@/hooks/useLoadOptions';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { IClientCompanyCreate } from '@/interfaces/ClientCompany';
import { useCreateClientCompanyMutation } from '@/services/clientCompany';

const StoreClientCompany: ForwardRefRenderFunction<IModalMethods> = (
  _,
  ref
) => {
  const ModalRef = useRef<ElementRef<typeof Modal>>(null);
  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<IClientCompanyCreate>();

  const { loadPlacesAutocomplete } = useLoadOptions();
  const [createClientCompanyAsync] = useCreateClientCompanyMutation();

  const showOrHiddenModal = () => ModalRef.current?.showOrHiddenModal();

  useImperativeHandle(ref, () => ({
    showOrHiddenModal,
  }));

  const onSubmit: SubmitHandler<IClientCompanyCreate> = ({ ...newData }) => {
    toast.promise(
      createClientCompanyAsync({
        ...newData,
        address: newData.address?.placeId as any,
      }).unwrap(),
      {
        pending: `Creating the client company...`,
        success: {
          render() {
            ModalRef.current?.showOrHiddenModal();
            return (
              <p style={{ marginBottom: 0, textAlign: 'center' }}>
                {`The client company has been successfully created 🤩`}
              </p>
            );
          },
        },
        error: {
          render({ data }: ToastContentProps<FetchBaseQueryError>) {
            if (data?.status === 422) {
              return 'The fields you filled out seem to be incorrect.';
            }
            return 'An error occurred';
          },
        },
      }
    );
  };

  const onError: SubmitErrorHandler<IClientCompanyCreate> = error =>
    console.log(error);

  return (
    <Modal
      ref={ModalRef}
      title={'Add a client company'}
      requiredLabel
      onSubmit={handleSubmit(onSubmit, onError)}
    >
      <Col>
        <Row className="gx-3">
          <Col lg={6}>
            <Form.Group className="mb-3">
              <Form.Label>
                Name <span className="text-danger">*</span> :
              </Form.Label>
              <Form.Control
                type="text"
                {...register('name', {
                  required: 'This field is required.',
                  minLength: {
                    value: 2,
                    message: 'This field must contain at least 2 characters.',
                  },
                  maxLength: {
                    value: 255,
                    message: 'This field must contain at most 255 characters.',
                  },
                })}
                isInvalid={!!errors.name}
              />
              <Form.Control.Feedback type="invalid">
                {errors.name?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col lg={6}>
            <Form.Group className="mb-3">
              <Form.Label>
                Adresse<span className="text-danger">*</span> :
              </Form.Label>
              <Controller
                control={control}
                name="address"
                rules={{
                  required: "L'adresse est obligatoire",
                }}
                render={({ field }) => (
                  <AsyncSelectComponent
                    loadOptions={loadPlacesAutocomplete}
                    value={field.value}
                    closeMenuOnSelect
                    isClearable
                    onChange={field.onChange}
                    components={{
                      Menu: CustomGoogleMenu,
                    }}
                    getOptionLabel={option => option.name}
                    getOptionValue={option => option.place_id}
                  />
                )}
              />
              <Form.Control.Feedback
                type="invalid"
                className={classNames({
                  'd-block': errors.address,
                })}
              >
                {errors.address?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col lg={6}>
            <Form.Group className="mb-3">
              <Form.Label>
                SIREN <span className="text-danger">*</span> :
              </Form.Label>
              <Form.Control
                placeholder="ex : SIREN Number"
                type="text"
                {...register('siren', {
                  required: 'This field is required.',
                  minLength: {
                    value: 2,
                    message: 'The SIREN must contain at least 2 characters.',
                  },
                  maxLength: {
                    value: 15,
                    message: 'The SIREN must contain at most 15 characters.',
                  },
                })}
                isInvalid={!!errors.siren}
              />
              <Form.Control.Feedback type="invalid">
                {errors.siren?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
      </Col>
    </Modal>
  );
};

export default forwardRef(StoreClientCompany);
