import { BookingCommissionRuleType } from '@/interfaces/BookingCommissionRules';
import api from './api';

export const bookingCommissionRuleApi = api.injectEndpoints({
  endpoints: (build) => ({
    getBookingCommissionRule: build.query<BookingCommissionRuleType[], void>({
      query: () => ({
        url: '/v2/booking-commission-rules',
      }),
    }),
    updateBookingCommissionRule: build.mutation({
      query: (body) => ({
        url: '/v2/booking-commission-rules',
        method: 'PUT',
        body,
      }),
    }),
  }),
})

export const {
  useGetBookingCommissionRuleQuery,
  useUpdateBookingCommissionRuleMutation,
} = bookingCommissionRuleApi;