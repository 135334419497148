import GoogleMap from '@/components/common/map/GoogleMaps';
import LegendMapCard from './LegendMapCard';
import { FC, useCallback, useState } from 'react';
import BrandedCar from '@/assets/img/map-view-illustration/comin_noir.png';
import NormalCar from '@/assets/img/map-view-illustration/comin_blanc.png';
import Loader from '../../Loader';
import ErrorComponent from '../../ErrorComponent';
import { useLiveActivityDatasQuery } from '@/services/liveActivity';
import { InfoWindow, Marker } from '@react-google-maps/api';
import './mapCardStyle.css';

type RideMapCardProps = {
  className?: string;
  style?: React.CSSProperties;
};

export const MapCardView: FC<RideMapCardProps> = ({
  className = '',
  style,
}) => {
  const [coor, setCoordinates] = useState<{ lat: number; lng: number }>({
    lat: 48.8608685,
    lng: 2.34,
  });

  const [activeMarker, setActiveMarker] = useState<{
    id: string;
    content: string;
  } | null>(null);

  const {
    data: liveActivityData,
    isLoading: isGodViewInfosLoading,
    isError: isGodViewInfosError,
    error,
  } = useLiveActivityDatasQuery(
    {
      coordinates: {
        lat: coor.lat.toString() || '',
        lng: coor.lng.toString() || '',
      },
    },
    {
      pollingInterval: 3000,
    }
  );

  const getIconByStatus = useCallback((status: string) => {
    switch (status) {
      case 'completed':
        return 'http://maps.google.com/mapfiles/ms/icons/green-dot.png';
      case 'cancelled':
        return 'http://maps.google.com/mapfiles/ms/icons/red-dot.png';
      case 'driver_cancelled':
        return 'http://maps.google.com/mapfiles/ms/icons/orange-dot.png';
      case 'started':
      case 'accepted':
      case 'requesting':
        return 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png';
      default:
        return 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png';
    }
  }, []);

  const handleMarkerClick = (markerId: string, content: string) => {
    setActiveMarker({ id: markerId, content });
  };

  const handleInfoWindowClose = () => {
    setActiveMarker(null);
  };

  if (isGodViewInfosLoading) {
    return <Loader absolute />;
  } else if (isGodViewInfosError) {
    return <ErrorComponent error={error} absolute returnButton />;
  }

  const driversAroundFormatted = liveActivityData?.driversAroundFormatted ?? [];
  const lastRidesFormatted = liveActivityData?.lastRidesFormatted ?? [];
  const lastSearchesFormatted = liveActivityData?.lastSearchesFormatted ?? [];

  return (
    <div
      style={{
        borderRadius: 10,
        overflow: 'hidden',
        height: '100%',
        minHeight: '30vh',
        ...style,
      }}
      className={`${className} position-relative`}
    >
      <GoogleMap
        initialCenter={coor}
        positions={isLoaded =>
          !isLoaded
            ? []
            : [
                ...lastRidesFormatted.map(ride => (
                  <Marker
                    key={ride.rideId}
                    position={{
                      lat: ride.originLatitude,
                      lng: ride.originLongitude,
                    }}
                    icon={{
                      url: getIconByStatus(ride.status),
                      scaledSize: new google.maps.Size(50, 50),
                    }}
                    onClick={() =>
                      handleMarkerClick(
                        `${ride.rideId}`,
                        `Ride from ${ride.addressName}`
                      )
                    }
                  >
                    {activeMarker?.id === `${ride.rideId}` && (
                      <InfoWindow
                        options={{
                          maxWidth: 500,
                          minWidth: 200,
                        }}
                        position={{
                          lat: ride.originLatitude,
                          lng: ride.originLongitude,
                        }}
                        onCloseClick={handleInfoWindowClose}
                      >
                        <div>
                          <p style={{ padding: 0, margin: 0 }}>
                            Ride from {ride.addressName}
                          </p>
                          <a href={`/rides/${ride.rideId}`}>
                            View Ride Details
                          </a>
                        </div>
                      </InfoWindow>
                    )}
                  </Marker>
                )),
                ...lastSearchesFormatted.map(search => (
                  <Marker
                    key={search.searchId}
                    position={{
                      lat: search.originLatitude,
                      lng: search.originLongitude,
                    }}
                    icon={{
                      url: 'http://maps.google.com/mapfiles/ms/icons/purple-dot.png',
                      scaledSize: new google.maps.Size(50, 50),
                    }}
                    onClick={() =>
                      handleMarkerClick(
                        `${search.searchId}`,
                        `Search at ${search.addressName}`
                      )
                    }
                  >
                    {activeMarker?.id === `${search.searchId}` && (
                      <InfoWindow
                        options={{
                          maxWidth: 500,
                          minWidth: 200,
                        }}
                        position={{
                          lat: search.originLatitude,
                          lng: search.originLongitude,
                        }}
                        onCloseClick={handleInfoWindowClose}
                      >
                        <div>
                          <p style={{ padding: 0, margin: 0 }}>
                            Search at {search.addressName}
                          </p>
                          <a href={`/requests/${search.searchId}`}>
                            View Search Details
                          </a>
                        </div>
                      </InfoWindow>
                    )}
                  </Marker>
                )),
                ...driversAroundFormatted.map((driver, i) => (
                  <>
                    <Marker
                      key={driver.driverId ?? i}
                      position={{
                        lat: driver.latitude,
                        lng: driver.longitude,
                      }}
                      icon={{
                        url: driver.branding ? BrandedCar : NormalCar,
                        scaledSize: new google.maps.Size(20, 50),
                        strokeColor: '#FFFFFF',
                        fillColor: '#0000FF',
                        fillOpacity: 1,
                        rotation: 215,
                        anchor: new google.maps.Point(30, 36),
                      }}
                      onClick={() =>
                        handleMarkerClick(
                          driver.driverName,
                          `Driver: ${driver.driverName}`
                        )
                      }
                    >
                      {activeMarker?.id === driver.driverName && (
                        <InfoWindow
                          options={{
                            maxWidth: 200,
                            minWidth: 100,
                          }}
                          position={{
                            lat: driver.latitude,
                            lng: driver.longitude,
                          }}
                          onCloseClick={handleInfoWindowClose}
                        >
                          <div>
                            <a href={`/drivers/${driver.driverId}`}>
                              {driver.driverName}
                            </a>
                          </div>
                        </InfoWindow>
                      )}
                      {/* {activeMarker?.id === driver.driverName && (
                        <Tooltip
                          position={{
                            lat: driver.latitude,
                            lng: driver.longitude,
                          }}
                          onClose={handleInfoWindowClose}
                        >
                          <div>{activeMarker.content}</div>
                        </Tooltip>
                      )} */}
                    </Marker>
                  </>
                )),
              ]
        }
        onDragEnd={setCoordinates}
        options={{
          zoomControlOptions: {
            position: 0,
          },
          center: coor,
          disableDefaultUI: true,
          zoomControl: false,
          streetViewControl: false,
          styles: [
            {
              featureType: 'poi',
              elementType: 'labels',
              stylers: [
                {
                  visibility: 'off',
                },
              ],
            },
            {
              featureType: 'transit',
              elementType: 'labels',
              stylers: [
                {
                  visibility: 'off',
                },
              ],
            },
            {
              featureType: 'road',
              elementType: 'labels',
              stylers: [
                {
                  visibility: 'off',
                },
              ],
            },
            {
              featureType: 'landscape',
              elementType: 'labels',
              stylers: [
                {
                  visibility: 'off',
                },
              ],
            },
          ],
        }}
      />
      <LegendMapCard />
    </div>
  );
};
