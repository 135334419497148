import { ISelectOption } from './Select';
import { MultiValue } from 'react-select';
import ICategory from './Category';
import { IUserList } from './User/UserList';

export type DiscountType = 'percent' | 'fixed';

export const CATEGORY_OPTIONS: ISelectOption<string>[] = [
  {
    label: 'BDE Events',
    value: 'bde_events',
  },
  {
    label: 'Corporate Events',
    value: 'corporate_events',
  },
  {
    label: 'Influence',
    value: 'influence',
  },
  {
    label: 'Influencer Credits',
    value: 'influencer_credits',
  },
  {
    label: 'Content Creator Credits',
    value: 'content_creator_credits',
  },
  {
    label: 'Founders / Team',
    value: 'founders/team',
  },
  {
    label: 'Social Media',
    value: 'social_media',
  },
  {
    label: 'Mailing / Notification',
    value: 'Mailing/Notification',
  },
  {
    label: 'Flyer',
    value: 'flyer',
  },
  {
    label: 'Friends',
    value: 'friends',
  },
  {
    label: 'Auto assigned',
    value: 'auto_assigned',
  },
  {
    label: 'None',
    value: 'none',
  },
];

export const DISCOUNT_TYPES: ISelectOption<DiscountType>[] = [
  {
    label: '%',
    value: 'percent',
  },
  {
    label: '€',
    value: 'fixed',
  },
];

interface ICoupon {
  id: string;
  userId: string | null;
  name: string;
  description: string;
  code: string;
  discountAmount: number;
  discountType: DiscountType;
  maxDiscountAmount: number;
  minPurchaseAmount: number;
  limitPerUser: number;
  totalLimit: number;
  numberOfUses: number;
  numberOfAdds: number;
  minRideCompleted: number;
  maxRideCompleted: number;
  startAt: string;
  endAt: string;
  isArchived: boolean;
  excludeOffers?: ICategory[];
  createdAt: string;
  updatedAt: string;
  category: string;
  type: string | null;
}

export interface ICouponForm {
  name: string;
  userId?: string;
  description?: string;
  discountAmount: number;
  discountType: ISelectOption<DiscountType>;
  maxDiscountAmount: number;
  minPurchaseAmount: number;
  limitPerUser: number;
  totalLimit: number;
  minRideCompleted: number;
  maxRideCompleted: number;
  startAt: string;
  endAt: string;
  excludeOffers?: MultiValue<ICategory>;
  category: ISelectOption<string>;
}

export interface ICouponFormSend
  extends Omit<ICouponForm, 'discountType' | 'excludeOffers' | 'category'> {
  discountType: DiscountType;
  category: string;
  excludeOfferIds?: string[];
  isArchived: boolean;
}

export interface AssignCouponForm {
  userList: MultiValue<IUserList>;
  coupon: ICoupon;
}

export default ICoupon;
