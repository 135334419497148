import Modal from '@/components/common/Modal';
import Select, {
  AsyncSelectComponent,
  CustomGoogleMenu,
} from '@/components/common/Select';
import { IModalMethods } from '@/interfaces/Modal';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import {
  ElementRef,
  ForwardRefRenderFunction,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import {
  Controller,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { ToastContentProps, toast } from 'react-toastify';
import useLoadOptions from '@/hooks/useLoadOptions';
import classNames from 'classnames';
import { IClientCompanyCreate } from '@/interfaces/ClientCompany';
import { useCreateWeeklyEventMutation } from '@/services/weeklyEvent';
import {
  EVENT_TYPE_OPTIONS,
  INDICATOR_EVENT_OPTIONS,
  IWeeklyEventCreate,
} from '@/interfaces/WeeklyEvent';
import { DateTime } from 'luxon';

const StoreClientCompany: ForwardRefRenderFunction<IModalMethods> = (
  _,
  ref
) => {
  const ModalRef = useRef<ElementRef<typeof Modal>>(null);
  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<IWeeklyEventCreate>();

  const { loadPlacesAutocomplete } = useLoadOptions();
  const [createWeeklyEventAsync] = useCreateWeeklyEventMutation();

  const showOrHiddenModal = () => ModalRef.current?.showOrHiddenModal();

  useImperativeHandle(ref, () => ({
    showOrHiddenModal,
  }));

  const onSubmit: SubmitHandler<IWeeklyEventCreate> = ({ ...newData }) => {
    toast.promise(
      createWeeklyEventAsync({
        ...newData,
        address: newData.address?.placeId,
        type: newData.type.value,
        indicator: newData.indicator.value,
        nbOfAttendees: parseInt(newData.nbOfAttendees.toString()),
      }).unwrap(),
      {
        pending: `Creating the weekly event...`,
        success: {
          render() {
            ModalRef.current?.showOrHiddenModal();
            return (
              <p style={{ marginBottom: 0, textAlign: 'center' }}>
                {`The weekly event has been successfully created 🤩`}
              </p>
            );
          },
        },
        error: {
          render({ data }: ToastContentProps<FetchBaseQueryError>) {
            if (data?.status === 422) {
              return 'The fields you filled out seem to be incorrect.';
            }
            return 'An error occurred';
          },
        },
      }
    );
  };

  const onError: SubmitErrorHandler<IClientCompanyCreate> = error =>
    console.log(error);

  return (
    <Modal
      ref={ModalRef}
      title={'Add a weekly event'}
      requiredLabel
      onSubmit={handleSubmit(onSubmit, onError)}
    >
      <Col>
        <Row className="gx-3">
          <Col lg={6}>
            <Form.Group className="mb-3">
              <Form.Label>
                Name <span className="text-danger">*</span> :
              </Form.Label>
              <Form.Control
                type="text"
                {...register('name', {
                  required: 'This field is required.',
                  minLength: {
                    value: 2,
                    message: 'This field must contain at least 2 characters.',
                  },
                  maxLength: {
                    value: 255,
                    message: 'This field must contain at most 255 characters.',
                  },
                })}
                isInvalid={!!errors.name}
              />
              <Form.Control.Feedback type="invalid">
                {errors.name?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col lg={6}>
            <Form.Group className="mb-3">
              <Form.Label>
                Type <span className="text-danger">*</span>
              </Form.Label>
              <Controller
                control={control}
                name="type"
                render={({ field }) => (
                  <Select
                    options={EVENT_TYPE_OPTIONS}
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
              />
              <Form.Control.Feedback type="invalid">
                {errors.type?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col lg={6}>
            <Form.Group className="mb-3">
              <Form.Label>
                Start At <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="datetime-local"
                {...register('startDate', {
                  validate: value => {
                    if (DateTime.fromISO(value) <= DateTime.now()) {
                      return 'La date de début doit être supérieure à la date actuelle';
                    }
                  },
                  required: 'La date de début est obligatoire',
                })}
                isInvalid={!!errors.startDate?.message}
              />
              <Form.Control.Feedback type="invalid">
                {errors.startDate?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col lg={6}>
            <Form.Group className="mb-3">
              <Form.Label>
                End At <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="datetime-local"
                {...register('endDate', {
                  validate: value => {
                    if (DateTime.fromISO(value) <= DateTime.now()) {
                      return 'La date de début doit être supérieure à la date actuelle';
                    }
                  },
                  required: 'La date de début est obligatoire',
                })}
                isInvalid={!!errors.endDate?.message}
              />
              <Form.Control.Feedback type="invalid">
                {errors.endDate?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col lg={6}>
            <Form.Group className="mb-3">
              <Form.Label>
                Adresse<span className="text-danger">*</span> :
              </Form.Label>
              <Controller
                control={control}
                name="address"
                rules={{
                  required: "L'adresse est obligatoire",
                }}
                render={({ field }) => (
                  <AsyncSelectComponent
                    loadOptions={loadPlacesAutocomplete}
                    value={field.value}
                    closeMenuOnSelect
                    isClearable
                    onChange={field.onChange}
                    components={{
                      Menu: CustomGoogleMenu,
                    }}
                    getOptionLabel={option => option.name}
                    getOptionValue={option => option.place_id}
                  />
                )}
              />
              <Form.Control.Feedback
                type="invalid"
                className={classNames({
                  'd-block': errors.address,
                })}
              >
                {errors.address?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col lg={6}>
            <Form.Group className="mb-3">
              <Form.Label>
                Nb of Attendees <span className="text-danger">*</span> :
              </Form.Label>
              <Form.Control
                type="number"
                min={0}
                {...register('nbOfAttendees', {
                  required: 'This field is required.',
                })}
                isInvalid={!!errors.nbOfAttendees}
              />
              <Form.Control.Feedback type="invalid">
                {errors.nbOfAttendees?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col lg={6}>
            <Form.Group className="mb-3">
              <Form.Label>
                Indicator <span className="text-danger">*</span> :
              </Form.Label>
              <Controller
                control={control}
                name="indicator"
                render={({ field }) => (
                  <Select
                    options={INDICATOR_EVENT_OPTIONS}
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
              />
              <Form.Control.Feedback type="invalid">
                {errors.indicator?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
      </Col>
    </Modal>
  );
};

export default forwardRef(StoreClientCompany);
