import SoftBadge from '@/components/common/SoftBadge';
import { GOOGLEMAPSWEBLINK } from '@/constants/places';
import { capitalizeString } from '@/helpers/utils';
import { BookingStatus } from '@/interfaces/Booking';
import { useGetBookingByIdQuery } from '@/services/bookings';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { Card, Col, Row, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';

interface BookingDetailCardProps {
  bookingId: string;
}

export const BookingDetailCard = ({ bookingId }: BookingDetailCardProps) => {
  const { data, isLoading } = useGetBookingByIdQuery(bookingId);

  const statusColor = useMemo(() => {
    switch (data?.status.toUpperCase()) {
      case BookingStatus.BOOKED:
      case BookingStatus.COMPLETED:
        return 'success';
      case BookingStatus.CANCELLED:
        return 'danger';
      case BookingStatus.DRIVER_ARRIVED:
      case BookingStatus.ON_GOING:
        return 'info';
      default:
        return 'info';
    }
  }, [data]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Row className="g-3">
        <Col xs={12}>
          <Card className="font-sans-serif">
            <Card.Body>
              <Row className="gx-3">
                <Col xs={12} md={6} className="mb-3 mb-md-0">
                  <Table borderless className="fs--1 fw-medium mb-0">
                    <tbody>
                      <tr>
                        <td className="p-1" style={{ width: '35%' }}>
                          Origin :
                        </td>
                        <td className="p-1 text-600">
                          <Link
                            to={`${GOOGLEMAPSWEBLINK}/${data?.originPlaceId}`}
                          >
                            {data?.originAddress}
                          </Link>
                        </td>
                      </tr>
                      <tr>
                        <td className="p-1" style={{ width: '35%' }}>
                          Destination :
                        </td>
                        <td className="p-1 text-600">
                          <Link
                            to={`${GOOGLEMAPSWEBLINK}/${data?.destinationPlaceId}`}
                          >
                            {data?.destinationAddress}
                          </Link>
                        </td>
                      </tr>
                      <tr>
                        <td className="p-1" style={{ width: '35%' }}>
                          Start Date :
                        </td>
                        <td className="p-1 text-600">
                          {DateTime.fromISO(data?.startDate).toFormat(
                            'dd LLL yyyy'
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="p-1" style={{ width: '35%' }}>
                          Start Time :
                        </td>
                        <td className="p-1 text-600">
                          {DateTime.fromISO(data?.time, {
                            zone: 'utc',
                          }).toFormat('HH:mm')}
                        </td>
                      </tr>
                      <tr>
                        <td className="p-1" style={{ width: '35%' }}>
                          Status :
                        </td>
                        <td className="p-1 text-600">
                          <SoftBadge bg={statusColor}>{data?.status}</SoftBadge>
                        </td>
                      </tr>
                      <tr>
                        <td className="p-1" style={{ width: '35%' }}>
                          Comments :
                        </td>
                        <td className="p-1">{data?.comments}</td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
                <Col xs={12} md={6} className="mb-3 mb-md-0">
                  <Table borderless className="fs--1 fw-medium mb-0">
                    <tbody>
                      <tr>
                        <td className="p-1" style={{ width: '35%' }}>
                          Flight / Train number :
                        </td>
                        <td className="p-1">
                          {data!.flightNumber} {data!.trainNumber}
                        </td>
                      </tr>
                      <tr>
                        <td className="p-1" style={{ width: '35%' }}>
                          Passenger Fullname :
                        </td>
                        <td className="p-1 text-600">
                          {capitalizeString(
                            data?.passengerFirstname +
                              ' ' +
                              data?.passengerLastname
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="p-1" style={{ width: '35%' }}>
                          Passenger Email :
                        </td>
                        <td className="p-1">
                          <Link
                            to={`mailto:${data?.passengerEmail}`}
                            className="text-600"
                          >
                            {data!.passengerEmail}
                          </Link>
                        </td>
                      </tr>
                      <tr>
                        <td className="p-1" style={{ width: '35%' }}>
                          Passenger phone :
                        </td>
                        <td className="p-1">
                          <a
                            href={`https://wa.me/${data?.passengerPhone.replace(
                              /[^0-9]/g,
                              ''
                            )}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: '#007bff' }}
                            className="text-decoration-none"
                          >
                            {data?.passengerPhone}
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td className="p-1" style={{ width: '35%' }}>
                          Author Fullname :
                        </td>
                        <td className="p-1 text-600">
                          <Link to={`/users/${data?.authorId}`}>
                            {capitalizeString(
                              data?.authorFirstname + ' ' + data?.authorLastname
                            )}
                          </Link>
                        </td>
                      </tr>
                      <tr>
                        <td className="p-1" style={{ width: '35%' }}>
                          Driver Fullname :
                        </td>
                        <td className="p-1 text-600">
                          <Link to={`/drivers/${data?.driverId}`}>
                            {data?.driverFirstname} {data?.driverLastname}
                          </Link>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};
