import {
  useGetBookingCommissionRuleQuery,
  useUpdateBookingCommissionRuleMutation,
} from '@/services/bookingCommissionRule';
import React, { useEffect } from 'react';
import { Card, Form, Row, Col, Button } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

export const BookingCommissionRules = () => {
  const { data: commissions } = useGetBookingCommissionRuleQuery();
  const [updateAsync] = useUpdateBookingCommissionRuleMutation();

  const { control, handleSubmit, reset } = useForm();

  const onSubmit = (data: any) => {
    toast.promise(updateAsync(data), {
      pending: 'Updating driver price commissions ...',
      success: 'Driver price commission updated successfully',
      error: 'Failed to update driver price',
    });
  };

  const onError = (errors: any) => {
    console.error(errors);
  };

  useEffect(() => {
    if (commissions) {
      reset({
        below_30: commissions.find(c => c.minAmount === null)
          ?.commissionPercentage,
        between_30_40: commissions.find(c => c.minAmount === 3000)
          ?.commissionPercentage,
        between_40_60: commissions.find(c => c.minAmount === 4000)
          ?.commissionPercentage,
        above_60: commissions.find(c => c.minAmount === 6000)
          ?.commissionPercentage,
      });
    }
  }, [commissions, reset]);

  return (
    <Card className="mt-3">
      <Card.Header>Driver Price</Card.Header>
      <Card.Body>
        <Form>
          <Row>
            <Col lg={6}>
              <Form.Group>
                <Form.Label>Below 30€</Form.Label>
                <Controller
                  name="below_30"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Form.Control
                      {...field}
                      type="number"
                      placeholder="Enter percentage"
                    />
                  )}
                />
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group>
                <Form.Label>Between 30€ and 40€</Form.Label>
                <Controller
                  name="between_30_40"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Form.Control
                      {...field}
                      type="number"
                      placeholder="Enter percentage"
                    />
                  )}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <Form.Group>
                <Form.Label>Between 40€ and 60€</Form.Label>
                <Controller
                  name="between_40_60"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Form.Control
                      {...field}
                      type="number"
                      placeholder="Enter percentage"
                    />
                  )}
                />
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group>
                <Form.Label>Above 60€</Form.Label>
                <Controller
                  name="above_60"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Form.Control
                      {...field}
                      type="number"
                      placeholder="Enter percentage"
                    />
                  )}
                />
              </Form.Group>
            </Col>
          </Row>
          <Button
            className="mt-3"
            type="submit"
            onClick={handleSubmit(onSubmit, onError)}
          >
            Save Driver Price percent Changes
          </Button>
        </Form>
      </Card.Body>
    </Card>
  );
};
