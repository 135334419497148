import ErrorComponent from "@/components/common/ErrorComponent";
import Loader from "@/components/common/Loader";
import { ICardDetailsProps } from "@/interfaces/Card";
import { FC, useEffect } from "react";
import { Card, Col, Row, Table } from "react-bootstrap";
import IReward from "@/interfaces/Reward";
import { useGetRewardQuery } from "@/services/rewards";
import SoftBadge from "@/components/common/SoftBadge";
import { getColorFromIsArchived, getColorFromRewardType, getColorFromUserType, getReelArchivedName, getReelRewardTypeName, getReelUserTypeName } from "@/helpers/UserHelpers";
import PageHeader from "@/components/common/header/PageHeader";
import { DateTime } from "luxon";

const RewardDetailsCard: FC<ICardDetailsProps<IReward>> = ({
    id,
    callBack
  }) => {
    const { data, isLoading, isError, error} = useGetRewardQuery(id);

    useEffect(() => {
      data && callBack?.(data);
    }, [data]);

    if (isLoading) {
        return <Loader absolute />;
    } else if (isError) {
        return <ErrorComponent error={error} absolute returnButton />;
    } else if (!data) {
        return (
        <ErrorComponent error={{ status: 404, data: {} }} absolute returnButton />
        );
    }
    
    return(
    <Row className="g-2">
      <Col xs={12}>
         {/* Page header */}
         <PageHeader title={`Reward Details: ${data.title} / ${data.title} (EN)`}>
              <p className="fs--1 mt-1">
                {DateTime.fromISO(data?.createdAt).toFormat(
                            "dd LLL yyyy 'at' HH:mm"
                          )}
              </p>
              <div>
                <strong className="me-2">Status : </strong>
                  <SoftBadge
                  bg={getColorFromIsArchived(data.isArchived)}
                  className="fs--2"
                  >
                    {getReelArchivedName(data.isArchived)}
                  </SoftBadge>
              </div>
            </PageHeader>
            {/* end of page header */}

            {/* Reward Info */}

            <Card className="mb-3 mt-3">
              <Card.Body>
                <Row>
                  <Col md={6} lg={4} className="mb-4 mb-lg-0">
                    <h5 className="mb-3 fs-0">Types</h5>
                    <h6 className="mb-2">Reward Type : 
                        <SoftBadge
                          bg={getColorFromRewardType(data.type)}
                          className={"text-xl-center ms-2"}
                          >
                            {getReelRewardTypeName(data.type)}
                        </SoftBadge>
                    </h6>
                    <h6 className="mb-2">User Type : 
                      <SoftBadge
                        bg={getColorFromUserType(data.userType)}
                        className={"text-xl-center ms-2"}
                        >
                        {getReelUserTypeName(data.userType)}
                      </SoftBadge>
                    </h6>
                  
                    {data.partnership && (
                      <h6 className="mb-2">Partnership: {data.partnership.name}</h6>
                    )}
                
                  </Col>
                  <Col md={6} lg={4} className="mb-4 mb-lg-0">
                    <h5 className="mb-3 fs-0">Limits</h5>
                    <h6 className="mb-2">Limit : {data.limit} </h6>
                    <h6 className="mb-2">Points : {data.price} </h6>
                  </Col>
                  <Col md={6} lg={4}>
                    <h5 className="mb-3 fs-0">Discount Info : </h5>
                    <div className="d-flex">
                      <div className="flex-1">
                        <h6 className="mb-2">Discount Amount : {data.discountAmount !== null ? `${data.discountAmount}€` : null}</h6>
                        <h6 className="mb-2">Max Discount Amount &#40;€&#41; : {data.maxDiscountAmount !== null ? `${data.maxDiscountAmount}€` : null}</h6>
                        <h6 className="mb-2">Min Purchase Amount : {data.minPurchaseAmount !== null ? `${data.minPurchaseAmount}€` : null}</h6>      
                        <h6 className="mb-2">Min Ride Completed : </h6>                                          
                      </div>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>

            {/* End of reward info */}

            {/* Description Section */}
            <Card className="mb-3 mt-3">
              <Card.Body>
                <Row>
                  <Col md={6} lg={6} className="mb-4 mb-lg-0">
                    <h5 className="mb-3 fs-0">Description Title</h5>
                    <p className="mb-1 fs--1">
                      <strong>{data.descriptionTitle}</strong>
                    </p>
                  </Col>
                  <Col md={6} lg={6} className="mb-4 mb-lg-0">
                    <h5 className="mb-3 fs-0">Description</h5>
                    <p className="mb-1 fs--1">
                      <strong>{data.description}</strong>
                    </p>
                  </Col>
                </Row>
              </Card.Body>
            </Card>

            <Card className="mb-3 mt-3">
              <Card.Body>
                <Row>
                  <Col md={6} lg={6} className="mb-4 mb-lg-0">
                    <h5 className="mb-3 fs-0">Description Title (EN)</h5>
                    <p className="mb-1 fs--1">
                      <strong>{data.descriptionTitleEn}</strong>
                    </p>
                  </Col>
                  <Col md={6} lg={6} className="mb-4 mb-lg-0">
                    <h5 className="mb-3 fs-0">Description (EN)</h5>
                    <p className="mb-1 fs--1">
                      <strong>{data.descriptionEn}</strong>
                    </p>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            {/* End of Description Section */}

            {/* Partnership Section */}

            {data.partnership && (
              <PageHeader title={`Partnership Details: ${data.partnership.name}`}>
                <p className="fs--1 mt-1">
                  {DateTime.fromISO(data.partnership.createdAt).toFormat(
                    "dd LLL yyyy 'at' HH:mm"
                  )}
                </p>
                <div>
                  <strong className="me-2">Category : </strong>
                  {data.partnership.category}
                </div>
                <div>
                  <strong className="me-2">Description : </strong>
                  {data.partnership.description}
                </div>
              </PageHeader>
            )}


            {/* End of Partnership Section */}
      </Col>
    </Row>
    );
}

export default RewardDetailsCard;