import { IClientCompany } from '@/interfaces/ClientCompany';
import { IFilters } from '@/interfaces/Fetch';
import {
  useDeleteClientCompanyMutation,
  useGetClientCompaniesQuery,
} from '@/services/clientCompany';
import { selectCurrentUserConfig } from '@/services/slices/authSlice';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { ColumnDef, TableState } from '@tanstack/react-table';
import { ElementRef, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import IconButton from '@/components/common/IconButton';
import AreYouSurToContinueModal from '@/components/modals/AreYouSurToContinueModal';
import Table from '@/components/common/table/Table';
import AllClientCompaniesHeader from '@/components/table-headers/clientCompany/AllClientCompanyHeader';
import { GOOGLEMAPSWEBLINK } from '@/constants/places';
import { splitText } from '@/helpers/utils';
import { toast } from 'react-toastify';

const ClientCompanyView: React.FC = () => {
  const userConfig = useSelector(selectCurrentUserConfig);

  const DeleteModalRef =
    useRef<ElementRef<typeof AreYouSurToContinueModal>>(null);

  const [state, setState] = useState<Partial<TableState> & IFilters>({
    pagination: {
      pageIndex: 0,
      pageSize: 15,
    },
    globalFilter: '',
  });

  const { data, isLoading } = useGetClientCompaniesQuery({
    ...state.pagination,
    query: state.globalFilter,
    ...(state.sorting?.[0] && {
      sortBy: state.sorting[0].id,
      sortDirection: state.sorting[0].desc ? 'desc' : 'asc',
    }),
  });

  const [deleteClientCompanyAsync] = useDeleteClientCompanyMutation();

  const totalCompanies = data?.meta?.total;

  const onDelete = (id: string) => {
    toast.promise(deleteClientCompanyAsync(id), {
      pending: 'Suppression en cours...',
      success: 'La compagnie a été supprimée avec succès',
      error: 'Une erreur est survenue lors de la suppression de la compagnie',
    });
    DeleteModalRef.current?.showOrHiddenModal();
  };

  const columns: ColumnDef<IClientCompany>[] = useMemo(
    () => [
      {
        header: 'Actions',
        enableSorting: false,
        footer: props => props.column.id,
        accessorFn: ({ id }) => (
          <div className="gap-2 d-flex">
            <Link to={`/clientCompany/${id}/edit`}>
              <IconButton
                variant="nolina-default"
                size="sm"
                icon={faEdit}
                transform="shrink-3"
                iconAlign="middle"
              />
            </Link>
            {userConfig.isAdminOrSuperAdmin && (
              <IconButton
                variant="outline-danger"
                size="sm"
                icon={faTrash}
                onClick={() => DeleteModalRef.current?.showOrHiddenModal(id)}
                transform="shrink-3"
                iconAlign="middle"
              />
            )}
          </div>
        ),
        id: 'actions',
        cell: info => info.getValue(),
      },
      {
        header: 'Name',
        accessorFn: ({ name }) => name,
        id: 'name',
      },
      {
        header: 'Adress',
        accessorFn: ({ address }) => (
          <Link to={`${GOOGLEMAPSWEBLINK}${address?.placeId}`}>
            {splitText(address?.address, 30)}
          </Link>
        ),
        id: 'addresse',
        cell: info => info.getValue(),
      },
    ],
    [totalCompanies, state.pagination?.pageIndex, state.pagination?.pageSize]
  );

  return (
    <div>
      <AreYouSurToContinueModal
        ref={DeleteModalRef}
        onContinue={onDelete}
        title="Souhaitez-vous vraiment supprimé la compagnie : {{id}} ?"
      />
      <Table
        HeaderComponent={AllClientCompaniesHeader}
        columns={columns}
        state={state}
        loading={isLoading}
        tableName="Companies"
        onStateChange={setState}
        data={data?.data}
        meta={data?.meta}
      />
    </div>
  );
};

export default ClientCompanyView;
