import api from '../api';
import { IFilters, IPaginateResponse } from '@/interfaces/Fetch';
import { createFormDataRequest } from '@/helpers/utils';
import { WeeklyFleetManager } from '@/interfaces/WeeklyFleetManager';

export const weeklyFleetmanager = api.injectEndpoints({
  endpoints: build => ({
    getWeeklyFleetManager: build.query<
      IPaginateResponse<WeeklyFleetManager>,
      [string, IFilters]
    >({
      query: ([companyId, data]) => {
        const params = createFormDataRequest(data);
        return {
          url: `weekly-fleet-manager-report/companies/${companyId}`,
          params,
        };
      },
      providesTags: ['WeeklyFleetManager'],
    }),
    sendEmailToManager: build.mutation<
      { message: string },
      { week: number; month: number; year: number; companyId: string; type: 'csv' | 'pdf' }
    >({
      query: ({ companyId, week, month, year, type }) => ({
        url: `weekly-fleet-manager-report/companies/${companyId}/send-email`,
        method: 'POST',
        body: {
          week,
          month,
          year,
          type,
        },
      }),
    }),
    generatePDFWeeklyReport: build.mutation<{ message: string }, void>({
      query: () => ({
        url: '/weekly-fleet-manager-report/generate/pdf',
        method: 'POST',
      }),
    }),
    generateCSVWeeklyReport: build.mutation<{ message: string }, void>({
      query: () => ({
        url: '/weekly-fleet-manager-report/generate/csv',
        method: 'POST',
      }),
    }),
    sendWeeklyPDFReport: build.mutation<{ message: string }, void>({
      query: () => ({
        url: '/weekly-fleet-manager-report/send-email/pdf',
        method: 'POST',
      }),
    }),
    sendWeeklyCSVReport: build.mutation<{ message: string }, void>({
      query: () => ({
        url: '/weekly-fleet-manager-report/send-email/csv',
        method: 'POST',
      }),
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetWeeklyFleetManagerQuery,
  useSendEmailToManagerMutation,
  useGeneratePDFWeeklyReportMutation,
  useGenerateCSVWeeklyReportMutation,
  useSendWeeklyCSVReportMutation,
  useSendWeeklyPDFReportMutation,
} = weeklyFleetmanager;
