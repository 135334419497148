import { FC } from 'react';
import { useParams } from 'react-router-dom';
import ErrorComponent from '@/components/common/ErrorComponent';
import { Col, Row } from 'react-bootstrap';
import GovernanceHeader from '@/components/card-headers/governances/GovernanceHeader';
import GovernanceDetailsCard from '@/components/cards/governances/GovernanceDetailsCard';
import GovernanceVotesChartCard from '@/components/cards/governances/GovernanceVotesChartCard';
import GovernancePageHeaderCard from '@/components/cards/governances/GovernancePageHeader';
import GovernanceInfoCard from '@/components/cards/governances/GovernanceInfoCard';

const GovernanceView: FC = () => {
  const { id } = useParams<{ id: string }>();

  if (!id) {
    return (
      <ErrorComponent error={{ status: 404, data: {} }} absolute returnButton />
    );
  }

  return (
    <div>
      <GovernanceHeader id={id} />
      <Row className="g-3 mb-3">
        <Col xxl={6}>
          <GovernancePageHeaderCard id={id} card />
        </Col>
        <Col xxl={6}>
          <GovernanceInfoCard id={id} card />
        </Col>
      </Row>
      <Row className="g-3 mb-3"></Row>
      <Row className="g-3 mb-3">
        <Col xxl={6} xl={6} lg={6}>
          <GovernanceVotesChartCard id={id} card />
        </Col>
        <Col xxl={6} xl={6} lg={6}>
          <GovernanceDetailsCard id={id} card />
        </Col>
      </Row>
    </div>
  );
};

export default GovernanceView;
