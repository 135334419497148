import ErrorComponent from '@/components/common/ErrorComponent';
import Loader from '@/components/common/Loader';
import SoftBadge from '@/components/common/SoftBadge';
import Table from '@/components/common/table/Table';
import AllRideOffersHeader from '@/components/table-headers/rides/AllRideOffersHeader';
import { GOOGLEMAPS_COORD_LINK } from '@/constants/places';
import {
  getColorFromDriverNotificationStatusColor,
  getDriverNotificationStatusName,
} from '@/helpers/DriverHelpers';
import { getOfferStatusTimeStamp } from '@/helpers/UserHelpers';
import { truncate } from '@/helpers/utils';
import { RequestOffer } from '@/interfaces/Request/IOffer';
import { IDriverNotificationFilters } from '@/interfaces/Ride/DriverNotification';
import { useGetRideOffersQuery } from '@/services/requests/offers';
import { ColumnDef, TableState } from '@tanstack/react-table';
import dayjs from 'dayjs';
import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';

type RequestOffersCardTableProps = {
  id: string;
};

const RequestOffersCard: React.FC<RequestOffersCardTableProps> = ({ id }) => {
  const [state, setState] = useState<
    Partial<TableState> & IDriverNotificationFilters
  >({
    pagination: {
      pageIndex: 0,
      pageSize: 15,
    },
    globalFilter: '',
    sorting: [
      {
        id: 'created_at',
        desc: true,
      },
    ],
  });

  const { data, isLoading, isError, error } = useGetRideOffersQuery([
    id,
    {
      ...state.pagination,
      query: state.globalFilter,
      ...(state.sorting?.[0] && {
        sortBy: state.sorting[0].id,
        sortDirection: state.sorting[0].desc ? 'desc' : 'asc',
      }),
    },
  ]);

  const columns = useRef<ColumnDef<RequestOffer>[]>([
    {
      header: 'Offer Id',
      enableSorting: false,
      footer: props => props.column.id,
      accessorFn: ({ driverRideRequest: { offerId } }) => truncate(offerId, 10),
      id: 'actions',
      cell: info => info.getValue(),
    },
    {
      header: 'Driver',
      footer: props => props.column.id,
      accessorFn: ({ driverProfile, driverId }) => (
        <Link to={`/drivers/${driverId}`}>{driverProfile.fullName}</Link>
      ),
      id: 'fullname',
      cell: info => info.getValue(),
    },
    {
      header: 'Offer Status',
      footer: props => props.column.id,
      accessorFn: ({
        driverRideRequest: { offerStatus, requestTimoutedAt },
      }) => {
        const offerStatus_ = requestTimoutedAt ? 'timouted' : offerStatus;

        const colorFromRequestTime =
          getColorFromDriverNotificationStatusColor(offerStatus_);
        return (
          <SoftBadge
            bg={colorFromRequestTime}
            className={'my-2 text-xl-center'}
          >
            {getDriverNotificationStatusName(offerStatus)}
          </SoftBadge>
        );
      },
      id: 'offzerStatus',
      cell: info => info.getValue(),
    },
    {
      header: 'Last Location',
      footer: props => props.column.id,
      accessorFn: ({ driverProfile: { latitude, longitude } }) => {
        if (!latitude || !longitude) return 'N/A';
        return (
          <Link to={GOOGLEMAPS_COORD_LINK(latitude, longitude)}>
            localisation google
          </Link>
        );
      },
      id: 'driver.lastLocation',
      cell: info => info.getValue(),
    },
    {
      header: 'Last Location Time',
      footer: props => props.column.id,
      accessorFn: ({ driverProfile }) => {
        if (!driverProfile?.latitude || !driverProfile.longitude) return 'N/A';
        return (
          <div>
            {dayjs(driverProfile.lastLocationAt).format('DD/MM/YYYY HH:mm')}
          </div>
        );
      },
      id: 'services.lastLocationTime',
      cell: info => info.getValue(),
    },
    {
      header: 'Time & Distance to Pickup',
      footer: props => props.column.id,
      accessorFn: ({ driverRideRequest: { distancePickUp, ETA } }) => (
        <>
          <div>
            {ETA} - {distancePickUp}
          </div>
        </>
      ),
      id: 'timeAndDistanceToPU',
      cell: info => info.getValue(),
    },
    {
      header: 'DriverPrice',
      footer: props => props.column.id,
      accessorFn: ({ driverRideRequest: { driverPrice } }) =>
        (driverPrice / 100).toFixed(2),
      id: 'driverPrice',
      cell: info => info.getValue(),
    },
    {
      header: 'Premium Price',
      footer: props => props.column.id,
      accessorFn: ({
        driverPricePremium,
        driverRideRequest: { driverPrice },
      }) => (
        <>
          {driverPricePremium > 0 ? (
            driverPrice + driverPricePremium
          ) : (
            <SoftBadge bg={'info'} className={'my-2 text-xl-center'}>
              N/A
            </SoftBadge>
          )}
        </>
      ),
      id: 'driverPremium',
      cell: info => info.getValue(),
    },
    {
      header: 'Car Brand',
      enableSorting: false,
      footer: (props: any) => props.column.id,
      accessorFn: ({ driverVehicle, driverId }) => {
        return (
          <Link to={`/drivers/${driverId}/vehicles/${driverVehicle.id}`}>
            <div className="d-flex align-items-center">
              <span>
                {driverVehicle.brand} {driverVehicle.model}
              </span>
            </div>
          </Link>
        );
      },
      id: 'CarBrand',
      cell: info => info.getValue(),
    },

    {
      header: 'Timestamp Offer Received',
      footer: props => props.column.id,
      accessorFn: ({ driverRideRequest: { offerReceivedAt } }) => {
        if (!offerReceivedAt) return 'N/A';
        return (
          <div>{dayjs(offerReceivedAt).format('DD/MM/YYYY HH:mm:ss')}</div>
        );
      },
      id: 'offerReceivedAt',
      cell: info => info.getValue(),
    },
    {
      header: 'Timestamp Offer Accepted',
      footer: props => props.column.id,
      accessorFn: ({
        driverRideRequest: {
          requestCancelledAt,
          requestAcceptedAt,
          requestRejectedAt,
        },
      }) => {
        const offerStatus = getOfferStatusTimeStamp(
          requestCancelledAt,
          requestAcceptedAt,
          requestRejectedAt
        );
        if (offerStatus === 'N/A') {
          return (
            <SoftBadge bg={'info'} className={'my-2 text-xl-center'}>
              N/A
            </SoftBadge>
          );
        }
        return <div>{dayjs(offerStatus).format('DD/MM/YYYY HH:mm:ss')}</div>;
      },
      id: 'created_at',
      cell: info => info.getValue(),
    },
  ]).current;

  if (isLoading) {
    return <Loader absolute />;
  } else if (isError) {
    return <ErrorComponent error={error} />;
  }

  if (data?.data.length === 0) {
    return (
      <div className="no-data-message">
        <h3>No offers available for this ride.</h3>
      </div>
    );
  }

  return (
    <>
      <Table
        HeaderComponent={AllRideOffersHeader}
        columns={columns}
        state={state}
        paddingRows="md"
        onStateChange={setState}
        data={data?.data}
        meta={data?.meta}
      />
    </>
  );
};

export default RequestOffersCard;
