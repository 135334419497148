import AreYouSurToContinueModal from '@/components/modals/AreYouSurToContinueModal';
import { IFilters } from '@/interfaces/Fetch';
import { IWeeklyEvent } from '@/interfaces/WeeklyEvent';
import { selectCurrentUserConfig } from '@/services/slices/authSlice';
import {
  useArchiveWeeklyEventMutation,
  useDeleteWeeklyEventMutation,
  useGetWeeklyEventsQuery,
} from '@/services/weeklyEvent';
import { ColumnDef, TableState } from '@tanstack/react-table';
import { ElementRef, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import IconButton from '@/components/common/IconButton';
import { faArchive, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { GOOGLEMAPSWEBLINK } from '@/constants/places';
import { splitText } from '@/helpers/utils';
import { DateTime } from 'luxon';
import SoftBadge from '@/components/common/SoftBadge';
import Table from '@/components/common/table/Table';
import AllWeeklyEventHeader from '@/components/table-headers/weeklyEvent/AllWeeklyEventHeader';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const WeeklyEventsView = () => {
  const userConfig = useSelector(selectCurrentUserConfig);

  const DeleteModalRef =
    useRef<ElementRef<typeof AreYouSurToContinueModal>>(null);

  const [state, setState] = useState<Partial<TableState> & IFilters>({
    pagination: {
      pageIndex: 0,
      pageSize: 15,
    },
    globalFilter: '',
  });

  const { data, isLoading } = useGetWeeklyEventsQuery({
    ...state.pagination,
    query: state.globalFilter,
    ...(state.sorting?.[0] && {
      sortBy: state.sorting[0].id,
      sortDirection: state.sorting[0].desc ? 'desc' : 'asc',
    }),
  });

  const [deleteAsync] = useDeleteWeeklyEventMutation();
  const [archiveWeeklyEventAsync, {isLoading: isArchivedLoading}] = useArchiveWeeklyEventMutation();

  const totalWeeklyEvents = data?.meta?.total;

  const onDelete = (id: string) => {
    toast.promise(deleteAsync(id), {
      pending: 'Deletion in progress...',
      success: 'The event has been successfully deleted',
      error: 'An error occurred while deleting the event',
    });
    DeleteModalRef.current?.showOrHiddenModal();
  };

  const getReelArchivedName = (isArchived: boolean) =>
    isArchived ? 'unarchived' : 'archived';

  const handleArchiveToggle = (id: string, isArchived: boolean) => {
    toast.promise(archiveWeeklyEventAsync(id), {
      pending: 'Archiving in progress...',
      success: `The event has been successfully ${getReelArchivedName(
        isArchived
      )}`,
      error: 'An error occurred while archiving the event',
    });
  };

  const buildTypeColor = (type: string) => {
    switch (type) {
      case 'concert':
        return { color: '#1b3a57', bg: '#cddff7' };
      case 'event':
        return { color: '#006400', bg: '#e6f2e6' };
      case 'night_club':
        return { color: '#ff8c00', bg: '#ffe6cc' };
      case 'festival':
        return { color: '#8B0000', bg: '#f2e6e6' };
      case 'event_comin':
        return { color: '#800080', bg: '#f2e6f2' };
      default:
        return { color: '#b30047', bg: '#f7cddc' };
    }
  };

  const columns: ColumnDef<IWeeklyEvent>[] = useMemo(
    () => [
      {
        header: 'Actions',
        enableSorting: false,
        footer: props => props.column.id,
        accessorFn: ({ id, isArchived }) => (
          <div className="gap-2 d-flex">
            <Link to={`/weeklyEvents/${id}/edit`}>
              <IconButton
                variant="nolina-default"
                size="sm"
                icon={faEdit}
                transform="shrink-3"
                iconAlign="middle"
              />
            </Link>
            {userConfig.isAdminOrSuperAdmin && (
              <>
                <IconButton
                  variant="outline-danger"
                  size="sm"
                  icon={faTrash}
                  onClick={() => DeleteModalRef.current?.showOrHiddenModal(id)}
                  transform="shrink-3"
                  iconAlign="middle"
                />
                <Button
                  variant={isArchived ? 'danger' : 'success'}
                  size="sm"
                  className="ms-2"
                  onClick={() => handleArchiveToggle(id, isArchived)}
                  disabled={isArchivedLoading}
                >
                  <FontAwesomeIcon icon={faArchive} />
                </Button>
              </>
            )}
          </div>
        ),
        id: 'actions',
        cell: info => info.getValue(),
      },
      {
        header: 'Name',
        accessorFn: ({ name }) => name,
        id: 'name',
      },
      {
        header: 'Status',
        accessorFn: ({ indicator }) => (
          <SoftBadge bg={indicator === 'active' ? 'success' : 'danger'}>
            {indicator}
          </SoftBadge>
        ),
        id: 'status',
        cell: info => info.getValue(),
      },
      {
        header: 'Start Date\nEnd Date',
        accessorFn: ({ startDate, endDate }) => (
          <>
            {DateTime.fromISO(startDate).toFormat('dd/MM/yyyy HH:mm')}
            <p className="mb-0 text-500">
              {DateTime.fromISO(endDate).toFormat('dd/MM/yyyy HH:mm')}
            </p>
          </>
        ),
        id: 'date',
        cell: info => info.getValue(),
      },
      {
        header: 'Adress',
        accessorFn: ({ address }) => (
          <Link to={`${GOOGLEMAPSWEBLINK}${address?.placeId}`}>
            {splitText(address?.address, 30)}
          </Link>
        ),
        id: 'addresse',
        cell: info => info.getValue(),
      },
      {
        header: 'Type',
        accessorFn: ({ type }) => (
          <SoftBadge
            bg={buildTypeColor(type).bg}
            textColor={buildTypeColor(type).color}
          >
            {type}
          </SoftBadge>
        ),
        id: 'type',
        cell: info => info.getValue(),
      },
      {
        header: 'Indicator',
        accessorFn: ({ indicator }) => (
          <SoftBadge bg={indicator === 'public' ? 'primary' : 'warning'}>
            {indicator}
          </SoftBadge>
        ),
        id: 'indicator',
        cell: info => info.getValue(),
      },
      {
        header: 'Nb Of Attendees',
        accessorFn: ({ nbOfAttendees }) => nbOfAttendees,
        id: 'attendees',
        cell: info => info.getValue(),
      },
    ],
    [totalWeeklyEvents, state.pagination?.pageIndex, state.pagination?.pageSize]
  );

  return (
    <div>
      <AreYouSurToContinueModal
        ref={DeleteModalRef}
        onContinue={onDelete}
        title="Do you really want to delete the event with id : {{id}} ?"
      />

      <Table
        HeaderComponent={AllWeeklyEventHeader}
        columns={columns}
        state={state}
        loading={isLoading}
        tableName="Companies"
        onStateChange={setState}
        data={data?.data}
        meta={data?.meta}
      />
    </div>
  );
};

export default WeeklyEventsView;
