import Loader from '@/components/common/Loader';
import Select, {
  AsyncSelectComponent,
  CustomGoogleMenu,
} from '@/components/common/Select';
import { format } from 'date-fns';
import {
  CompanyBusinessTypeSelect,
  CompanyCountrySelect,
  CompanyCurrencySelect,
  CompanyPersonRoleSelect,
  CompanyStatusSelect,
} from '@/helpers/CompanyHelper';
import getDifferenceBetweenTwoObjects from '@/helpers/FormHelpers';
import useLoadOptions from '@/hooks/useLoadOptions';
import { CompanyFormUpdate, CompanyFormUpdateSend } from '@/interfaces/Company';
import { parsePhoneNumber } from 'libphonenumber-js';
import { GENDERS } from '@/interfaces/User';
import {
  useGetCompanyQuery,
  useUpdateCompanyMutation,
} from '@/services/companies';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import classNames from 'classnames';
import { FC, useEffect } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import DatePicker from '@/components/common/DatePicker';
import {
  Controller,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { ToastContentProps, toast } from 'react-toastify';
import { validateAge, validateEmail } from '@/helpers/utils';

interface UpdateCompanyCardProps {
  id: string;
}

const TVA_OPTIONS = [
  { value: true, label: 'Oui' },
  { value: false, label: 'Non' },
];

const UpdateCompanyCard: FC<UpdateCompanyCardProps> = ({ id }) => {
  const { data, isLoading } = useGetCompanyQuery(id);
  const {
    control,
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<DeepPartial<CompanyFormUpdate>>();

  const { loadPlacesAutocomplete } = useLoadOptions();
  const [updateCompanyMutation] = useUpdateCompanyMutation();
  const navigation = useNavigate();

  const onSubmit: SubmitHandler<DeepPartial<CompanyFormUpdate>> = ({
    ...newData
  }) => {
    const result = getDifferenceBetweenTwoObjects<CompanyFormUpdate>(
      data,
      newData
    );

    const payload: CompanyFormUpdateSend = {
      ...result,
      addressId: result?.address?.place_id,
      phone: result.phone
        ? parsePhoneNumber(result.phone, 'FR').format('E.164')
        : undefined,
      supportPhone: result.supportPhone
        ? parsePhoneNumber(result.supportPhone, 'FR').format('E.164')
        : undefined,
      supportAddressId: result.supportAddress?.placeId ?? undefined,
      bankAccountCountry: result.bankAccountCountry?.value,
      bankAccountCurrency: result.bankAccountCurrency?.value,
      defaultCurrency: result.defaultCurrency?.value,
      status: result.status?.value,
      businessType: result.businessType?.value,
      country: result.country?.value,
      bankAccountHolderName: result.bankAccountHolderName,
      bankAccountNumber: result.bankAccountNumber,
      taxId: result.taxId,
      vatId: result.vatId,
      stripeAccountId: result.stripeAccountId,
      tvaRedevable: result.tvaRedevable.value,
      owner: {
        phone: result.owner?.phone
          ? parsePhoneNumber(result.owner.phone, 'FR').format('E.164')
          : undefined,
        addressId: result.owner?.addressId?.place_id ?? undefined,
        title: result.owner?.title,
        gender: result.owner?.gender?.value,
        nationality: result.owner?.nationality?.value,
        birthdate: result.owner?.birthdate
          ? format(result.owner.birthdate, 'yyyy-MM-dd')
          : undefined,
        firstname: result.owner?.firstname,
        lastname: result.owner?.lastname,
        email: result.owner?.email,
        percentOwnership: result.owner?.percentOwnership,
      },
    };

    toast.promise(updateCompanyMutation([id, payload]).unwrap(), {
      pending: `Modification de la compagnie en cours...`,
      success: {
        render() {
          navigation(-1);
          return (
            <p
              style={{ marginBottom: 0, textAlign: 'center' }}
            >{`La compagnie à bien été modifié 🤩`}</p>
          );
        },
      },
      error: {
        render({ data }: ToastContentProps<FetchBaseQueryError>) {
          if (data?.status === 422) {
            return 'Les champs que vous avez remplis semblent être incorrects.';
          }
          return 'Une erreur est survenue';
        },
      },
    });
  };

  const onError: SubmitErrorHandler<DeepPartial<CompanyFormUpdate>> = error =>
    console.log(error);

  useEffect(() => {
    if (data) {
      reset({
        ...data,
        defaultCurrency: CompanyCurrencySelect.find(
          v => v.value === data.defaultCurrency
        ),
        address: {
          ...data.address,
          place_id: data.address?.placeId,
        },
        supportAddress: {
          ...data.supportAddress,
          place_id: data.supportAddress?.placeId,
        },
        bankAccountCountry: CompanyCountrySelect.find(
          v => v.value === data.bankAccountCountry
        ),
        bankAccountCurrency: CompanyCurrencySelect.find(
          v => v.value === data.bankAccountCurrency
        ),
        status: CompanyStatusSelect.find(v => v.value === data.status),
        businessType: CompanyBusinessTypeSelect.find(
          v => v.value === data.businessType
        ),
        country: CompanyCountrySelect.find(v => v.value === data.country),
        bankAccountHolderName: data.bankAccountHolderName,
        taxId: data.taxId,
        vatId: data.vatId,
        stripeAccountId: data.stripeAccountId,
        tvaRedevable: TVA_OPTIONS.find(v => v.value === data.tvaRedevable),
        owner: {
          ...data.owner,
          gender: data.owner?.gender
            ? GENDERS.find(v => v.value === data.owner?.gender)
            : undefined,
          nationality: data.owner?.nationality
            ? CompanyCountrySelect.find(
                v => v.value === data.owner?.nationality
              )
            : undefined,
          role: CompanyPersonRoleSelect.find(v => v.value === data.owner?.role),
          title: data.owner?.title,
          addressId: {
            ...data.owner?.address,
            place_id: data.owner?.address?.placeId,
          },
          firstname: data.owner?.firstname,
          lastname: data.owner?.lastname,
          email: data.owner?.email,
          percentOwnership: data.owner?.percentOwnership,
        },
      });
    }
  }, [data, reset]);

  if (isLoading) {
    return <Loader absolute />;
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit, onError)}>
      <Card>
        <Card.Body className="bg-light">
          <h4 className="mb-0">Edit a company</h4>
          <h6 className="mt-1 text-secondary">
            The required fields are marked with{' '}
            <span className="text-danger">*</span>.
          </h6>
          <Row className="gx-3">
            <Col>
              <Row className="gx-3">
                <Col lg={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Name <span className="text-danger">*</span> :
                    </Form.Label>
                    <Form.Control
                      placeholder="ex : NOEL10"
                      type="text"
                      {...register('name', {
                        required: 'Ce champ est requis.',
                        minLength: {
                          value: 2,
                          message:
                            'Ce champ doit contenir au moins 2 caractères.',
                        },
                        maxLength: {
                          value: 255,
                          message:
                            'Ce champ doit contenir au maximum 255 caractères.',
                        },
                      })}
                      isInvalid={!!errors.name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.name?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col lg={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Adresse<span className="text-danger">*</span> :
                    </Form.Label>
                    <Controller
                      control={control}
                      name="address"
                      rules={{
                        required: "L'adresse est obligatoire",
                      }}
                      render={({ field }) => (
                        <AsyncSelectComponent
                          loadOptions={loadPlacesAutocomplete}
                          value={field.value}
                          closeMenuOnSelect
                          isClearable
                          onChange={field.onChange}
                          components={{
                            Menu: CustomGoogleMenu,
                          }}
                          getOptionLabel={option => option.name}
                          getOptionValue={option => option.place_id}
                        />
                      )}
                    />
                    <Form.Control.Feedback
                      type="invalid"
                      className={classNames({
                        'd-block': errors.address,
                      })}
                    >
                      {errors.address?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col lg={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Phone <span className="text-danger">*</span> :
                    </Form.Label>
                    <Form.Control
                      placeholder="ex : 0618109832"
                      type="tel"
                      {...register('phone', {
                        required: 'Ce champ est requis.',
                        minLength: {
                          value: 2,
                          message:
                            'Le téléphone doit contenir au moins 2 caractères.',
                        },
                        maxLength: {
                          value: 20,
                          message:
                            'Le téléphone name doit contenir au maximum 12 caractères.',
                        },
                      })}
                      isInvalid={!!errors.phone}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.phone?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col lg={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Country <span className="text-danger">*</span> :
                    </Form.Label>
                    <Controller
                      control={control}
                      name="country"
                      render={({ field }) => (
                        <Select
                          options={CompanyCountrySelect}
                          value={field.value}
                          onChange={field.onChange}
                          required
                        />
                      )}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.country?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col lg={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Email <span className="text-danger">*</span> :
                    </Form.Label>
                    <Form.Control
                      placeholder="ex : contact@wearecomin.com"
                      type="email"
                      {...register('email', {
                        validate: value => {
                          return value && validateEmail(value);
                        },
                      })}
                      isInvalid={!!errors.email}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.email?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col lg={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Default Currency <span className="text-danger">*</span> :
                    </Form.Label>
                    <Controller
                      control={control}
                      name="defaultCurrency"
                      render={({ field }) => (
                        <Select
                          options={CompanyCurrencySelect}
                          value={field.value}
                          onChange={field.onChange}
                          required
                        />
                      )}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.defaultCurrency?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col lg={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Bank Account Country{' '}
                      <span className="text-danger">*</span> :
                    </Form.Label>
                    <Controller
                      control={control}
                      name="bankAccountCountry"
                      render={({ field }) => (
                        <Select
                          options={CompanyCountrySelect}
                          value={field.value}
                          onChange={field.onChange}
                          required
                        />
                      )}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.bankAccountCountry?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col lg={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Status <span className="text-danger">*</span> :
                    </Form.Label>
                    <Controller
                      control={control}
                      name="status"
                      disabled
                      render={({ field }) => (
                        <Select
                          options={CompanyStatusSelect}
                          value={field.value}
                          onChange={field.onChange}
                        />
                      )}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.status?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col lg={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Business Type <span className="text-danger">*</span> :
                    </Form.Label>
                    <Controller
                      control={control}
                      name="businessType"
                      render={({ field }) => (
                        <Select
                          options={CompanyBusinessTypeSelect}
                          value={field.value}
                          onChange={field.onChange}
                          required
                        />
                      )}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.businessType?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col lg={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Bank Account Currency{' '}
                      <span className="text-danger">*</span> :
                    </Form.Label>
                    <Controller
                      control={control}
                      name="bankAccountCurrency"
                      render={({ field }) => (
                        <Select
                          options={CompanyCurrencySelect}
                          value={field.value}
                          onChange={field.onChange}
                          required
                        />
                      )}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.bankAccountCurrency?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col lg={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Bank Account Holder Name{' '}
                      <span className="text-danger">*</span> :
                    </Form.Label>
                    <Form.Control
                      placeholder="ex : John Doe"
                      type="text"
                      {...register('bankAccountHolderName', {
                        required: 'Ce champ est requis.',
                        minLength: {
                          value: 2,
                          message:
                            'Le bank account holder name doit contenir au moins 2 caractères.',
                        },
                        maxLength: {
                          value: 255,
                          message:
                            'Le bank account holder name doit contenir au maximum 255 caractères.',
                        },
                      })}
                      isInvalid={!!errors.bankAccountHolderName}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.bankAccountHolderName?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col lg={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Bank Account Number <span className="text-danger">*</span>{' '}
                      :
                    </Form.Label>
                    <Form.Control
                      placeholder="ex : 123456789"
                      type="text"
                      {...register('bankAccountNumber', {
                        required: 'Ce champ est requis.',
                        minLength: {
                          value: 2,
                          message:
                            'Le bank account number doit contenir au moins 2 caractères.',
                        },
                        maxLength: {
                          value: 255,
                          message:
                            'Le bank account number doit contenir au maximum 255 caractères.',
                        },
                      })}
                      isInvalid={!!errors.bankAccountNumber}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.bankAccountNumber?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col lg={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Support address :</Form.Label>
                    <Controller
                      control={control}
                      name="supportAddress"
                      rules={{
                        required: "L'adresse est obligatoire",
                      }}
                      render={({ field }) => (
                        <AsyncSelectComponent
                          loadOptions={loadPlacesAutocomplete}
                          value={field.value}
                          closeMenuOnSelect
                          isClearable
                          required
                          onChange={field.onChange}
                          components={{
                            Menu: CustomGoogleMenu,
                          }}
                          getOptionLabel={option => option.name}
                          getOptionValue={option => option.place_id}
                        />
                      )}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.supportAddress?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col lg={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Support email :</Form.Label>
                    <Form.Control
                      placeholder="ex : contact@comin.com"
                      type="text"
                      {...register('supportEmail', {
                        pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                        minLength: {
                          value: 2,
                          message:
                            'Le support email doit contenir au moins 2 caractères.',
                        },
                        maxLength: {
                          value: 255,
                          message:
                            'Le support email doit contenir au maximum 255 caractères.',
                        },
                      })}
                      isInvalid={!!errors.supportEmail}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.supportEmail?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col lg={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Support phone :</Form.Label>
                    <Form.Control
                      placeholder="ex : +330617628275"
                      type="phone"
                      {...register('supportPhone', {
                        minLength: {
                          value: 2,
                          message:
                            'Le support Phone doit contenir au moins 2 caractères.',
                        },
                        maxLength: {
                          value: 20,
                          message:
                            'Le bank name doit contenir au maximum 255 caractères.',
                        },
                      })}
                      isInvalid={!!errors.supportPhone}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.supportPhone?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col lg={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Tax ID <span className="text-danger">*</span> :
                    </Form.Label>
                    <Form.Control
                      placeholder="ex : SIREN Number"
                      type="text"
                      {...register('taxId', {
                        required: 'Ce champ est requis.',
                        minLength: {
                          value: 2,
                          message:
                            'Le tax ID doit contenir au moins 2 caractères.',
                        },
                        maxLength: {
                          value: 15,
                          message:
                            'Le tax ID doit contenir au maximum 255 caractères.',
                        },
                      })}
                      isInvalid={!!errors.taxId}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.taxId?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col lg={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Vat ID <span className="text-danger">*</span> :
                    </Form.Label>
                    <Form.Control
                      placeholder="ex : TVA Number"
                      type="text"
                      {...register('vatId', {
                        required: 'Ce champ est requis.',
                        minLength: {
                          value: 2,
                          message:
                            'Le vatId doit contenir au moins 2 caractères.',
                        },
                        maxLength: {
                          value: 15,
                          message:
                            'Le vatId doit contenir au maximum 15 caractères.',
                        },
                      })}
                      isInvalid={!!errors.vatId}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.vatId?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col lg={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      TVA Redevable <span className="text-danger">*</span> :
                    </Form.Label>
                    <Controller
                      control={control}
                      name="tvaRedevable"
                      render={({ field }) => (
                        <Select
                          options={TVA_OPTIONS}
                          value={field.value}
                          onChange={field.onChange}
                        />
                      )}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.tvaRedevable?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Stripe ID <span className="text-danger">*</span> :
                    </Form.Label>
                    <Form.Control
                      placeholder="ex : TVA Number"
                      type="text"
                      {...register('stripeAccountId', {
                        required: 'Ce champ est requis.',
                      })}
                      isInvalid={!!errors.stripeAccountId}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.stripeAccountId?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>

              <Row className="bg-100 py-3 px-2 ">
                <h3 className="text-lg">Owner</h3>

                <Col lg={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Gender <span className="text-danger">*</span> :
                    </Form.Label>
                    <Controller
                      control={control}
                      name="owner.gender"
                      render={({ field }) => (
                        <Select
                          options={GENDERS}
                          value={field.value}
                          onChange={field.onChange}
                          required
                        />
                      )}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors['owner']?.gender?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col lg={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Firstname <span className="text-danger">*</span> :
                    </Form.Label>
                    <Form.Control
                      placeholder="ex : Leonard"
                      type="text"
                      {...register('owner.firstname', {
                        required: 'Ce champ est requis.',
                        minLength: {
                          value: 2,
                          message:
                            'Ce champ doit contenir au moins 2 caractères.',
                        },
                        maxLength: {
                          value: 255,
                          message:
                            'Ce champ doit contenir au maximum 255 caractères.',
                        },
                      })}
                      isInvalid={!!errors['owner']?.firstname}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors['owner']?.firstname?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col lg={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Lastname <span className="text-danger">*</span> :
                    </Form.Label>
                    <Form.Control
                      placeholder="ex : Bernard"
                      type="text"
                      {...register('owner.lastname', {
                        required: 'Ce champ est requis.',
                        minLength: {
                          value: 2,
                          message:
                            'Ce champ doit contenir au moins 2 caractères.',
                        },
                        maxLength: {
                          value: 255,
                          message:
                            'Ce champ doit contenir au maximum 255 caractères.',
                        },
                      })}
                      isInvalid={!!errors['owner']?.lastname}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors['owner']?.lastname?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col lg={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Nationality <span className="text-danger">*</span> :
                    </Form.Label>
                    <Controller
                      control={control}
                      name="owner.nationality"
                      render={({ field }) => (
                        <Select
                          options={CompanyCountrySelect}
                          value={field.value}
                          onChange={field.onChange}
                          required
                        />
                      )}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors['owner']?.nationality?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col lg={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Adresse<span className="text-danger">*</span> :
                    </Form.Label>
                    <Controller
                      control={control}
                      name="owner.addressId"
                      rules={{
                        required: "L'adresse est obligatoire",
                      }}
                      render={({ field }) => (
                        <AsyncSelectComponent
                          loadOptions={loadPlacesAutocomplete}
                          value={field.value}
                          closeMenuOnSelect
                          isClearable
                          required
                          onChange={field.onChange}
                          components={{
                            Menu: CustomGoogleMenu,
                          }}
                          getOptionLabel={option => option.name}
                          getOptionValue={option => option.place_id}
                        />
                      )}
                    />
                    <Form.Control.Feedback
                      type="invalid"
                      className={classNames({
                        'd-block': errors.address,
                      })}
                    >
                      {errors['owner']?.addressId?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Title <span className="text-danger">*</span> :
                    </Form.Label>
                    <Form.Control
                      placeholder="ex : Leonard"
                      type="text"
                      {...register('owner.title', {
                        required: 'Ce champ est requis.',
                        minLength: {
                          value: 2,
                          message:
                            'Ce champ doit contenir au moins 2 caractères.',
                        },
                        maxLength: {
                          value: 255,
                          message:
                            'Ce champ doit contenir au maximum 255 caractères.',
                        },
                      })}
                      isInvalid={!!errors['owner']?.title}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors['owner']?.title?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Email <span className="text-danger">*</span> :
                    </Form.Label>
                    <Form.Control
                      placeholder="ex : connexion@me.com"
                      type="text"
                      {...register('owner.email', {
                        pattern: /^\S+@\S+$/i,
                        required: 'Ce champ est requis.',
                        minLength: {
                          value: 2,
                          message:
                            'Ce champ doit contenir au moins 2 caractères.',
                        },
                        maxLength: {
                          value: 255,
                          message:
                            'Ce champ doit contenir au maximum 255 caractères.',
                        },
                      })}
                      isInvalid={!!errors['owner']?.email}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors['owner']?.email?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col lg={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Phone <span className="text-danger">*</span> :
                    </Form.Label>
                    <Form.Control
                      placeholder="ex : 0618109832"
                      type="tel"
                      {...register('owner.phone', {
                        required: 'Ce champ est requis.',
                        minLength: {
                          value: 2,
                          message:
                            'Le téléphone doit contenir au moins 2 caractères.',
                        },
                        maxLength: {
                          value: 20,
                          message:
                            'Le téléphone name doit contenir au maximum 12 caractères.',
                        },
                      })}
                      isInvalid={!!errors['owner']?.phone}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors['owner']?.phone?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col lg={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Percent ownership <span className="text-danger">*</span> :
                    </Form.Label>
                    <Form.Control
                      placeholder="ex : 0"
                      type="number"
                      min={0}
                      max={100}
                      {...register('owner.percentOwnership', {
                        required: 'Ce champ est requis.',
                        min: {
                          value: 0,
                          message: 'Le pourcentage doit être au moins de 0.',
                        },
                        max: {
                          value: 100,
                          message:
                            'Le pourcentage doit être au maximum de 100.',
                        },
                      })}
                      isInvalid={!!errors['owner']?.percentOwnership}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors['owner']?.percentOwnership?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group className="mb-3 d-flex flex-column">
                    <Form.Label>
                      Birthdate <span className="text-danger">*</span> :
                    </Form.Label>
                    <Controller
                      control={control}
                      name="owner.birthdate"
                      render={({ field }) => (
                        <DatePicker
                          value={field.value}
                          onChange={field.onChange}
                          required
                        />
                      )}
                      rules={{
                        validate: value =>
                          value && !validateAge(value)
                            ? "L'owner doit avoir au moins 18 ans."
                            : undefined,
                        required: 'Ce champ est requis.',
                      }}
                    />

                    <Form.Control.Feedback type="invalid">
                      {errors['owner']?.birthdate?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Button
                type="submit"
                variant="success"
                disabled={isLoading}
                className="mt-3"
              >
                Update the company
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Form>
  );
};

export default UpdateCompanyCard;
