import { ITableHeaderComponentProps } from '@/interfaces/Table';
import {
  faCogs,
  faFilter,
  faPaperPlane,
  faPlus,
  faRefresh,
  faSearch,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ElementRef, FC, useRef } from 'react';
import {
  Button,
  Col,
  FormControl,
  InputGroup,
  OverlayTrigger,
  Popover,
  Row,
} from 'react-bootstrap';
import { useSelector } from 'react-redux';
import IconButton from '@/components/common/IconButton';
import { selectCurrentUserConfig } from '@/services/slices/authSlice';
import StoreCompanyModal from '@/components/modals/company/StoreCompanyModal';
import { useGetCompaniesQuery } from '@/services/companies';
import { useGenerateCSVWeeklyReportMutation, useGeneratePDFWeeklyReportMutation, useSendWeeklyPDFReportMutation } from '@/services/drivers/weeklyFleetManager';
import { toast } from 'react-toastify';

const AllCompaniesHeader: FC<ITableHeaderComponentProps> = ({
  onQueryChange,
  onShowFilters,
}) => {
  const userConfig = useSelector(selectCurrentUserConfig);
  const StoreCompanyModalRef =
    useRef<ElementRef<typeof StoreCompanyModal>>(null);
  const openCreateModal = () =>
    StoreCompanyModalRef.current?.showOrHiddenModal();

  const { refetch } = useGetCompaniesQuery({
    pageIndex: 0,
    pageSize: 15,
    query: '',
  });

  const [generatePDFAsync] = useGeneratePDFWeeklyReportMutation();
  const [generateCSVAsync] = useGenerateCSVWeeklyReportMutation();
  const [sendPDFAsync] = useSendWeeklyPDFReportMutation();

  const handleGeneratePDFReport = () => {
    toast.promise(generatePDFAsync().unwrap(), {
      pending: 'Generating PDF report...',
      success: 'Reports Generated',
      error: {
        render({ data }: any) {
          console.log(data)
          return data?.data?.message ?? 'An error occurred';
        },
      },
    });
  };

  const handleGenerateCSVReport = () => {
    toast.promise(generateCSVAsync().unwrap(), {
      pending: 'Generating CSV report...',
      success: 'Reports Generated',
      error: {
        render({ data }: any) {
          return data?.data?.message ?? 'An error occurred';
        },
      },
    });
  };

  const handleSendPDFReport = () => {
    toast.promise(sendPDFAsync().unwrap(), {
      pending: 'Sending PDF report...',
      success: 'Reports Sent',
      error: {
        render({ data }: any) {
          return data?.data?.message ?? 'An error occurred';
        },
      },
    });
  };

  return (
    <div className="d-lg-flex justify-content-between">
      {userConfig.isAdminOrSuperAdmin && (
        <StoreCompanyModal ref={StoreCompanyModalRef} />
      )}
      <Row className="flex-between-center gy-2 px-x1">
        <Col xs="auto" className="pe-0">
          <h6 className="mb-0">Companies</h6>
        </Col>
        <Col xs="auto">
          <InputGroup className="position-relative input-search-width">
            <FormControl
              size="sm"
              id="search"
              type="search"
              className="shadow-none"
              placeholder="Rechercher"
              onChange={e => onQueryChange(e.target.value)}
            />
            <Button
              size="sm"
              variant="outline-secondary"
              className="border-300 hover-border-secondary"
            >
              <FontAwesomeIcon icon={faSearch} className="fs--1" />
            </Button>
          </InputGroup>
        </Col>
      </Row>
      <div className="border-bottom border-200 my-3"></div>
      <div className="d-flex align-items-center justify-content-between justify-content-lg-end px-x1">
        {!!onShowFilters && (
          <>
            <IconButton
              variant="nolina-default"
              size="sm"
              icon={faFilter}
              transform="shrink-4"
              iconAlign="middle"
              onClick={onShowFilters}
              className="d-xl-none"
            >
              <span className="d-none d-sm-inline-block ms-1">Filtres</span>
            </IconButton>
            <div
              className="bg-300 mx-3 d-none d-lg-block d-xl-none"
              style={{ width: '1px', height: '29px' }}
            ></div>
          </>
        )}

        <div id="orders-actions">
          {userConfig?.isAdminOrSuperAdmin && (
            <>
              <IconButton
                variant="nolina-default"
                size="sm"
                icon={faPlus}
                onClick={openCreateModal}
                transform="shrink-3"
                iconAlign="middle"
              >
                <span className="d-none d-sm-inline-block d-xl-none d-xxl-inline-block ms-1">
                  Nouveau
                </span>
              </IconButton>
              <IconButton
                variant="nolina-default"
                size="sm"
                icon={faRefresh}
                transform="shrink-3"
                style={{ marginLeft: '10px' }}
                onClick={refetch}
              >
                <span className="d-none d-sm-inline-block ms-1">Refresh</span>
              </IconButton>
              <OverlayTrigger
                trigger="click"
                placement="bottom"
                containerPadding={50}
                overlay={
                  <Popover id="popover-basic">
                    <Popover.Body>
                      <Row>
                        <Col lg="12">
                          <IconButton
                            icon={faCogs}
                            variant="default"
                            size="sm"
                            transform="shrink-3"
                            style={{ marginBottom: '5px', boxShadow: 'none' }}
                            onClick={handleGeneratePDFReport}
                          >
                            Generate PDF
                          </IconButton>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="12">
                          <IconButton
                            icon={faCogs}
                            variant="default"
                            size="sm"
                            transform="shrink-3"
                            style={{ marginBottom: '5px', boxShadow: 'none' }}
                            onClick={handleGenerateCSVReport}
                          >
                            Generate CSV
                          </IconButton>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="12">
                          <IconButton
                            icon={faPaperPlane}
                            variant="default"
                            size="sm"
                            transform="shrink-3"
                            style={{ boxShadow: 'none' }}
                            onClick={handleSendPDFReport}
                          >
                            Send
                          </IconButton>
                        </Col>
                      </Row>
                    </Popover.Body>
                  </Popover>
                }
              >
                <Button
                  size="sm"
                  variant="nolina-default"
                  className="border-300 hover-border-secondary"
                  style={{ marginLeft: '10px' }}
                >
                  <FontAwesomeIcon icon={faFilter} className="fs--1" />
                  <span className="d-none d-sm-inline-block ms-1">
                    Weekly Reports
                  </span>
                </Button>
              </OverlayTrigger>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AllCompaniesHeader;
