import Table from '@/components/common/table/Table';
import { WeeklyFleetManagerHeader } from '@/components/table-headers/drivers/weeklyFleetManager/WeeklyFleetManagerHeader';
import { getWeekNumberOfMonth } from '@/helpers/utils';
import { IFilters } from '@/interfaces/Fetch';
import { WeeklyFleetManager } from '@/interfaces/WeeklyFleetManager';
import {
  useGetWeeklyFleetManagerQuery,
  useSendEmailToManagerMutation,
} from '@/services/drivers/weeklyFleetManager';
import { faMailBulk } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ColumnDef, TableState } from '@tanstack/react-table';
import { DateTime } from 'luxon';
import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

export const WeeklyReportTable = ({ companyId }: WeeklyReportTableProps) => {
  const [state, setState] = useState<Partial<TableState> & IFilters>({
    pagination: {
      pageIndex: 0,
      pageSize: 10,
    },
    globalFilter: '',
    sorting: [
      {
        id: 'created_at',
        desc: true,
      },
    ],
  });

  const { data, isLoading, isFetching } = useGetWeeklyFleetManagerQuery([
    companyId,
    {
      ...state.pagination,
      query: state.globalFilter,
      ...(state.sorting?.[0] && {
        sortBy: state.sorting[0].id,
        sortDirection: state.sorting[0].desc ? 'desc' : 'asc',
      }),
    },
  ]);

  const [sendReportMailAsync] = useSendEmailToManagerMutation();

  const handleSendReportPDFMail = (
    companyId: string,
    week: number,
    month: number,
    year: number,
    url: string,
  ) => {
    if (!url) {
      toast.error('No PDF report available');
      return;
    }

    toast.promise(
      sendReportMailAsync({
        companyId,
        week,
        month,
        year,
        type: 'pdf',
      }).unwrap(),
      {
        pending: 'Sending PDF report mail...',
        success: 'Report mail sent successfully!',
        error: 'Failed to send report mail',
      }
    );
  };

  const columns = useMemo<ColumnDef<WeeklyFleetManager>[]>(
    () => [
      {
        header: 'Week Month',
        footer: props => props.column.id,
        accessorFn: ({ month, week, year }) => {
          return `Week ${week} - ${DateTime.now()
            .set({ month })
            .setLocale('fr')
            .toFormat('LLL')} ${year}`;
        },
        id: 'weekMonth',
        cell: info => info.getValue(),
      },
      {
        header: 'CSV Link',
        footer: props => props.column.id,
        accessorFn: ({ csvUrl }) => <Link to={csvUrl}>Report Link</Link>,
        id: 'csvUrl',
        cell: info => info.getValue(),
      },
      {
        header: 'Send Report',
        footer: props => props.column.id,
        accessorFn: ({ companyId, week, month, year, pdfUrl }) => (
          <button className="btn btn-primary btn-sm">
            <FontAwesomeIcon
              onClick={() => handleSendReportPDFMail(companyId, week, month, year, pdfUrl)}
              icon={faMailBulk}
            />
          </button>
        ),
        id: 'sendMail1',
        cell: info => info.getValue(),
      },
    ],
    []
  );

  return (
    <Table
      HeaderComponent={WeeklyFleetManagerHeader}
      data={data?.data || []}
      columns={columns}
      meta={data?.meta}
      state={state}
      onStateChange={setState}
      loading={isLoading || isFetching}
    />
  );
};

interface WeeklyReportTableProps {
  companyId: string;
}
