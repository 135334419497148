import { ISelectOption } from '../Select';

export type GovernanceStatus =
  | 'voting_period'
  | 'passed'
  | 'rejected'
  | 'waiting';

export const GOVERNANCE_STATUS: ISelectOption<GovernanceStatus>[] = [
  {
    label: 'voting_period',
    value: 'voting_period',
  },
  {
    label: 'passed',
    value: 'passed',
  },
  {
    label: 'rejected',
    value: 'rejected',
  },
  {
    label: 'waiting',
    value: 'waiting',
  },
];

interface IGovernance {
  id: string;
  status: GovernanceStatus;
  title: string;
  titleEn: string;
  startAt: string;
  endAt: string;
  description: string;
  descriptionEn: string;
  context: string;
  contextEn: string;
  driverCourseCount: number;
  customerCourseCount: number;
  createdAt: string;
  updatedAt: string;
}

export interface IGovernanceForm {
  status: ISelectOption<GovernanceStatus>;
  title: string;
  titleEn: string;
  startAt: string;
  endAt: string;
  description: string;
  descriptionEn: string;
  context: string;
  contextEn: string;
  driverCourseCount: number;
  customerCourseCount: number;
}

export interface IGovernanceFormSend extends Omit<IGovernanceForm, 'status'> {
  status: GovernanceStatus;
}

export default IGovernance;
