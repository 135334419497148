import { TableState } from '@tanstack/react-table';
import { IFilters } from './Fetch';
import { PlaceAutocompleteResult } from './PlaceAutocomplete';
import { ISelectOption } from './Select';

export interface IBooking {
  id: string;
  status: string;
  comments: string;
  flightNumber: string;
  trainNumber: string;
  language: string;
  price: string;
  driverPrice: string;
  category: string;
  passengerId: string;
  passengerFirstname: string;
  passengerLastname: string;
  passengerEmail: string;
  passengerPhone: string;
  authorId: string;
  authorFirstname: string;
  authorLastname: string;
  authorEmail: string;
  authorPhone: string;
  originAddress: string;
  destinationAddress: string;
  createdAt: string;
  time: string;
  startDate: string;
  driverId?: string;
  driverFirstname?: string;
  driverLastname?: string;
  driverPhone?: string;
  companyName?: string;
  siren?: string;
  avatar?: string;
  licensePlate?: string;
  brand?: string;
  model?: string;
}

export enum BookingStatus {
  BOOKED = 'BOOKED',
  DRIVER_ARRIVED = 'DRIVER_ARRIVED',
  ON_GOING = 'ON_GOING',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED',
}

export interface IBookingForm {
  origin: PlaceAutocompleteResult;
  destination: PlaceAutocompleteResult;
  offerId: string;
  passengerId: string;
  driverId?: string;
  authorId: string;
  passengerFirstname: string;
  passengerLastname: string;
  passengerEmail: string;
  passengerPhone: string;
  time: string;
  language?: string;
  comments?: string;
  flightNumber?: string;
  trainNumber?: string;
  status?: BookingStatus;
  startDate: string;
  price: number;
  driverPrice: number;
}

export interface IBookingEditForm extends Omit<IBookingForm, 'status'> {
  status: ISelectOption<BookingStatus>;
}

export const STATUSES: ISelectOption<BookingStatus>[] = [
  {
    value: BookingStatus.DRIVER_ARRIVED,
    label: 'Driver Arrived',
  },
  {
    value: BookingStatus.ON_GOING,
    label: 'On Going',
  },
  {
    value: BookingStatus.COMPLETED,
    label: 'Completed',
  },
  {
    value: BookingStatus.CANCELLED,
    label: 'Cancelled',
  },
];

export interface IBookingFilters extends Partial<TableState>, IFilters {
  status?: BookingStatus;
}
