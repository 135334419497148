import Modal from '@/components/common/Modal';
import Select, { SelectPaginateAsync } from '@/components/common/Select';
import useLoadOptions from '@/hooks/useLoadOptions';
import { CATEGORY_OPTIONS, DISCOUNT_TYPES, ICouponForm } from '@/interfaces/Coupon';
import { IFilters } from '@/interfaces/Fetch';
import { IModalMethods } from '@/interfaces/Modal';
import { useStoreCouponMutation } from '@/services/coupons';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { DateTime } from 'luxon';
import {
  ElementRef,
  ForwardRefRenderFunction,
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Col, Form, InputGroup, Row } from 'react-bootstrap';
import {
  Controller,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { ToastContentProps, toast } from 'react-toastify';

const StoreCouponModal: ForwardRefRenderFunction<IModalMethods> = (_, ref) => {
  const ModalRef = useRef<ElementRef<typeof Modal>>(null);
  const {
    control,
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<ICouponForm>({
    defaultValues: {
      discountType: DISCOUNT_TYPES[0],
      category: CATEGORY_OPTIONS.at(-1),
    },
  });
  const { loadOffersOptions } = useLoadOptions();
  const defaultAdditionnal = useRef<IFilters>({
    pageIndex: 0,
    pageSize: 15,
  }).current;

  const [storeCouponAsync] = useStoreCouponMutation();

  const showOrHiddenModal = () => ModalRef.current?.showOrHiddenModal();

  const [isArchived, setIsArchived] = useState(false);

  useImperativeHandle(ref, () => ({
    showOrHiddenModal,
  }));

  const onSubmit: SubmitHandler<ICouponForm> = ({
    discountType,
    excludeOffers,
    category,
    discountAmount,
    maxDiscountAmount,
    minPurchaseAmount,
    limitPerUser,
    totalLimit,
    maxRideCompleted,
    minRideCompleted,
    ...data
  }) => {
    toast.promise(
      storeCouponAsync({
        discountType: discountType.value,
        category: category.value,
        excludeOfferIds: excludeOffers?.map(offer => offer.id),
        maxDiscountAmount: +maxDiscountAmount,
        discountAmount: +discountAmount,
        minPurchaseAmount: +minPurchaseAmount,
        limitPerUser: +limitPerUser,
        totalLimit: +totalLimit,
        maxRideCompleted: +maxRideCompleted,
        minRideCompleted: +minRideCompleted,
        ...data,
        isArchived,
      }).unwrap(),
      {
        pending: 'Ajout du coupon en cours...',
        success: {
          render() {
            reset();
            showOrHiddenModal();
            return (
              <p style={{ marginBottom: 0, textAlign: 'center' }}>
                Le coupon à bien été ajouté 🤩
              </p>
            );
          },
        },
        error: {
          render({ data }: ToastContentProps<FetchBaseQueryError>) {
            if (data?.status === 422) {
              return 'Les champs que vous avez remplis semblent être incorrects.';
            }

            return 'Une erreur est survenue';
          },
        },
      }
    );
  };

  const onError: SubmitErrorHandler<ICouponForm> = () => {};

  return (
    <Modal
      ref={ModalRef}
      title="Add a Coupon"
      requiredLabel
      onSubmit={handleSubmit(onSubmit, onError)}
    >
      <Row className="gx-3">
        <Col lg={12}>
          <Form.Group className="mb-3">
            <Form.Label>
              Name <span className="text-danger">*</span> :
            </Form.Label>
            <Form.Control
              placeholder="ex : NOEL10"
              type="text"
              {...register('name', {
                required: 'Ce champ est requis.',
                minLength: {
                  value: 2,
                  message: 'Ce champ doit contenir au moins 2 caractères.',
                },
                maxLength: {
                  value: 255,
                  message: 'Ce champ doit contenir au maximum 255 caractères.',
                },
              })}
              isInvalid={!!errors.name}
            />
            <Form.Control.Feedback type="invalid">
              {errors.name?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>
              Min. Ride Completed <span className="text-danger">*</span> :
            </Form.Label>
            <Form.Control
              placeholder="ex : 10"
              type="number"
              {...register('minRideCompleted', {
                required: 'Ce champ est requis.',
                minLength: {
                  value: 1,
                  message: 'Ce champ doit contenir au moins 1 caractères.',
                },
                maxLength: {
                  value: 255,
                  message: 'Ce champ doit contenir au maximum 255 caractères.',
                },
              })}
              isInvalid={!!errors.minRideCompleted}
            />
            <Form.Control.Feedback type="invalid">
              {errors.minRideCompleted?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>
              Max. Ride Completed <span className="text-danger">*</span> :
            </Form.Label>
            <Form.Control
              placeholder="ex : 10"
              type="number"
              {...register('maxRideCompleted', {
                required: 'Ce champ est requis.',
                minLength: {
                  value: 1,
                  message: 'Ce champ doit contenir au moins 1 caractères.',
                },
                maxLength: {
                  value: 255,
                  message: 'Ce champ doit contenir au maximum 255 caractères.',
                },
              })}
              isInvalid={!!errors.maxRideCompleted}
            />
            <Form.Control.Feedback type="invalid">
              {errors.maxRideCompleted?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>
              Discount Amount <span className="text-danger">*</span> :
            </Form.Label>
            <InputGroup>
              <Form.Control
                placeholder="ex : 10"
                type="number"
                {...register('discountAmount', {
                  required: 'Ce champ est requis.',
                  minLength: {
                    value: 2,
                    message:
                      'Le discount amount doit contenir au moins 2 caractères.',
                  },
                  maxLength: {
                    value: 255,
                    message:
                      'Le discount amount doit contenir au maximum 255 caractères.',
                  },
                })}
                isInvalid={!!errors.discountAmount}
              />
              <Controller
                control={control}
                name="discountType"
                render={({ field }) => (
                  <InputGroup.Text>{field.value.label}</InputGroup.Text>
                )}
              />
              <Form.Control.Feedback type="invalid">
                {errors.discountAmount?.message}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>
              Discount Type <span className="text-danger">*</span> :
            </Form.Label>
            <Controller
              control={control}
              name="discountType"
              render={({ field }) => (
                <Select
                  options={DISCOUNT_TYPES}
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
            <Form.Control.Feedback type="invalid">
              {errors.discountAmount?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>
              Max Discount Amount € <span className="text-danger">*</span> :
            </Form.Label>
            <Form.Control
              placeholder="ex : 10"
              type="number"
              {...register('maxDiscountAmount', {
                required: 'Ce champ est requis..',
              })}
              isInvalid={!!errors.maxDiscountAmount}
            />
            <Form.Control.Feedback type="invalid">
              {errors.maxDiscountAmount?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>
              Min Purchase Amount € <span className="text-danger">*</span> :
            </Form.Label>
            <Form.Control
              placeholder="ex : 10"
              type="number"
              {...register('minPurchaseAmount', {
                required: 'Ce champ est requis..',
                minLength: {
                  value: 2,
                  message:
                    'Le minPurchaseAmount doit contenir au moins 2 caractères.',
                },
                maxLength: {
                  value: 255,
                  message:
                    'Le minPurchaseAmount doit contenir au maximum 255 caractères.',
                },
              })}
              isInvalid={!!errors.minPurchaseAmount}
            />
            <Form.Control.Feedback type="invalid">
              {errors.minPurchaseAmount?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>
              Limit Per User <span className="text-danger">*</span> :
            </Form.Label>
            <Form.Control
              placeholder="ex : 10"
              type="number"
              {...register('limitPerUser', {
                required: 'Ce champ est requis..',
                maxLength: {
                  value: 255,
                  message:
                    'Le limitPerUser doit contenir au maximum 255 caractères.',
                },
              })}
              isInvalid={!!errors.limitPerUser}
            />
            <Form.Control.Feedback type="invalid">
              {errors.limitPerUser?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>
              Total Limit <span className="text-danger">*</span> :
            </Form.Label>
            <Form.Control
              placeholder="ex : 10"
              type="number"
              {...register('totalLimit', {
                required: 'Ce champ est requis..',
                minLength: {
                  value: 2,
                  message: 'Le totalLimit doit contenir au moins 2 caractères.',
                },
                maxLength: {
                  value: 255,
                  message:
                    'Le totalLimit doit contenir au maximum 255 caractères.',
                },
              })}
              isInvalid={!!errors.totalLimit}
            />
            <Form.Control.Feedback type="invalid">
              {errors.totalLimit?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>
              Start At <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="datetime-local"
              {...register('startAt', {
                validate: value => {
                  if (DateTime.fromISO(value) <= DateTime.now()) {
                    return 'La date de début doit être supérieure à la date actuelle';
                  }
                },
                required: 'La date de début est obligatoire',
              })}
              isInvalid={!!errors.startAt?.message}
            />
            <Form.Control.Feedback type="invalid">
              {errors.startAt?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>
              End At <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="datetime-local"
              {...register('endAt', {
                validate: value => {
                  if (DateTime.fromISO(value) < DateTime.now()) {
                    return 'La date de fin doit être supérieure à la date actuelle';
                  }
                },
                required: 'La date de fin est obligatoire',
              })}
              isInvalid={!!errors.endAt?.message}
            />
            <Form.Control.Feedback type="invalid">
              {errors.endAt?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>
              Exclude Offers <span className="text-danger">*</span>
            </Form.Label>
            <Controller
              control={control}
              name="excludeOffers"
              render={({ field }) => (
                <SelectPaginateAsync
                  loadOptions={loadOffersOptions}
                  getOptionLabel={option => option.name}
                  getOptionValue={option => option.id}
                  value={field.value}
                  onChange={field.onChange}
                  isMulti
                  additional={defaultAdditionnal}
                />
              )}
            />
            <Form.Control.Feedback type="invalid">
              {errors.excludeOffers?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>
              Category <span className="text-danger">*</span>
            </Form.Label>
            <Controller
              control={control}
              name="category"
              render={({ field }) => (
                <Select
                options={CATEGORY_OPTIONS}
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
            <Form.Control.Feedback type="invalid">
              {errors.category?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col className="mt-2">
          <Form.Label>Description</Form.Label>
          <Form.Control
            placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
            as="textarea"
            type="text"
            {...register('description', {
              minLength: {
                value: 3,
                message: 'Ce champ doit contenir au moins 3 caractères.',
              },
              maxLength: {
                value: 3000,
                message: 'Ce champ doit contenir au plus 255 caractères.',
              },
            })}
            style={{ minHeight: 100 }}
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default forwardRef(StoreCouponModal);
