import { MonthlyReportsData } from '@/interfaces/MonthlyReport';
import api from '../api';
import { IFilters, IPaginateResponse } from '@/interfaces/Fetch';
import { createFormDataRequest } from '@/helpers/utils';

export const montlhyReportApi = api.injectEndpoints({
  endpoints: build => ({
    getMonthlyReport: build.query<
      IPaginateResponse<MonthlyReportsData>,
      [string, IFilters]
    >({
      query: ([driverId, data]) => {
        const params = createFormDataRequest(data);
        return {
          url: `drivers/${driverId}/monthly-report`,
          params,
        };
      },
      providesTags: ['MonthlyReport'],
    }),
    sendMonthlyReportMail: build.mutation<
      { message: string },
      { month: number; year: number; driverId: string }
    >({
      query: ({ driverId, month, year }) => ({
        url: `/monthly-report/send-email/drivers/${driverId}`,
        method: 'POST',
        body: {
          month,
          year,
        },
      }),
    }),
    generateReport: build.mutation<void, void>({
      query: () => ({
        url: `/monthly-report/generate`,
        method: 'POST',
      }),
      invalidatesTags: ['MonthlyReport'],
    }),
    sendReportsToDrivers: build.mutation<{message: string}, void>({
      query: () => ({
        url: `/monthly-report/send`,
        method: 'POST',
      }),
      invalidatesTags: ['MonthlyReport'],
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetMonthlyReportQuery,
  useSendMonthlyReportMailMutation,
  useGenerateReportMutation,
  useSendReportsToDriversMutation,
} = montlhyReportApi;
