import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { FC, useEffect } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import {
  Controller,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { ToastContentProps, toast } from 'react-toastify';
import Loader from '@/components/common/Loader';
import Select from '@/components/common/Select';
import CardHeader from '@/components/card-headers/CardHeader';
import { GOVERNANCE_STATUS, IGovernanceForm } from '@/interfaces/Governance';
import { DateTime } from 'luxon';
import {
  useGetGovernanceQuery,
  useUpdateGovernanceMutation,
} from '@/services/governances';

interface UpdateGovernanceCardProps {
  id: string;
}

const UpdateGovernanceCard: FC<UpdateGovernanceCardProps> = ({ id }) => {
  const { data, isLoading } = useGetGovernanceQuery(id);
  const {
    control,
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<Partial<IGovernanceForm>>();

  const [editGovernanceMutation] = useUpdateGovernanceMutation();

  const navigation = useNavigate();

  const onSubmit: SubmitHandler<Partial<IGovernanceForm>> = ({
    status,
    ...data
  }) => {
    toast.promise(
      editGovernanceMutation([
        id,
        {
          ...data,
          status: status?.value,
        },
      ]).unwrap(),
      {
        pending: `Modification du governance en cours...`,
        success: {
          render() {
            navigation(-1);

            return (
              <p style={{ marginBottom: 0, textAlign: 'center' }}>
                {`Le governance à bien été modifié 🤩`}
              </p>
            );
          },
        },
        error: {
          render({ data }: ToastContentProps<FetchBaseQueryError>) {
            if (data?.status === 422) {
              return 'Les champs que vous avez remplis semblent être incorrects.';
            }
            return 'Une erreur est survenue';
          },
        },
      }
    );
  };

  const onError: SubmitErrorHandler<Partial<IGovernanceForm>> = error =>
    console.log(error);

  useEffect(() => {
    if (data) {
      const formattedStartAt = data.startAt
        ? DateTime.fromISO(data.startAt)
        : null;
      const formattedEndAt = data.startAt ? DateTime.fromISO(data.endAt) : null;
      reset({
        ...data,
        status: GOVERNANCE_STATUS.find(
          element => element.value === data.status
        ),
        startAt: formattedStartAt?.toFormat("yyyy-MM-dd'T'HH:mm") ?? '',
        endAt: formattedEndAt?.toFormat("yyyy-MM-dd'T'HH:mm") ?? '',
      });
    }
  }, [data]);

  if (isLoading) {
    return <Loader absolute />;
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit, onError)}>
      <Card>
        <CardHeader title="Modification du governance" className="m-0" />
        <Card.Body className="bg-light">
          <Row className="gx-3">
          <Col lg={12}>
              <Form.Group className="mb-3">
                <Form.Label>
                  Status <span className="text-danger">*</span> :
                </Form.Label>
                <Controller
                  control={control}
                  name="status"
                  render={({ field }) => (
                    <Select
                      options={GOVERNANCE_STATUS}
                      value={field.value}
                      onChange={field.onChange}
                    />
                  )}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.status?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>
                  Title <span className="text-danger">*</span> :
                </Form.Label>
                <Form.Control
                  placeholder="ex : Would you like a new rating system?"
                  type="text"
                  {...register('title', {
                    required: 'Ce champ est requis.',
                    minLength: {
                      value: 1,
                      message: 'Ce champ doit contenir au moins 1 caractères.',
                    },
                    maxLength: {
                      value: 255,
                      message:
                        'Ce champ doit contenir au maximum 255 caractères.',
                    },
                  })}
                  isInvalid={!!errors.title}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.title?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>
                  Title (EN) <span className="text-danger">*</span> :
                </Form.Label>
                <Form.Control
                  placeholder="ex : Would you like a new rating system?"
                  type="text"
                  {...register('titleEn', {
                    required: 'Ce champ est requis.',
                    minLength: {
                      value: 1,
                      message: 'Ce champ doit contenir au moins 1 caractères.',
                    },
                    maxLength: {
                      value: 255,
                      message:
                        'Ce champ doit contenir au maximum 255 caractères.',
                    },
                  })}
                  isInvalid={!!errors.titleEn}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.titleEn?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>
                  Start At <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="datetime-local"
                  {...register('startAt', {
                    validate: value => {
                      if (!value) return '';
                    },
                    required: 'La date de début est obligatoire',
                  })}
                  isInvalid={!!errors.startAt?.message}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.startAt?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>
                  End At <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="datetime-local"
                  {...register('endAt', {
                    validate: value => {
                      if (!value) return '';
                    },
                    required: 'La date de fin est obligatoire',
                  })}
                  isInvalid={!!errors.endAt?.message}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.endAt?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>
                  Driver Ride Count <span className="text-danger">*</span> :
                </Form.Label>
                <Form.Control
                  placeholder="ex : 10"
                  type="number"
                  {...register('driverCourseCount', {
                    required: 'Ce champ est requis.',
                    minLength: {
                      value: 1,
                      message: 'Ce champ doit contenir au moins 1 caractères.',
                    },
                    maxLength: {
                      value: 255,
                      message:
                        'Ce champ doit contenir au maximum 255 caractères.',
                    },
                  })}
                  isInvalid={!!errors.driverCourseCount}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.driverCourseCount?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>
                  User Ride Count <span className="text-danger">*</span> :
                </Form.Label>
                <Form.Control
                  placeholder="ex : 10"
                  type="number"
                  {...register('customerCourseCount', {
                    required: 'Ce champ est requis.',
                    minLength: {
                      value: 1,
                      message: 'Ce champ doit contenir au moins 1 caractères.',
                    },
                    maxLength: {
                      value: 255,
                      message:
                        'Ce champ doit contenir au maximum 255 caractères.',
                    },
                  })}
                  isInvalid={!!errors.customerCourseCount}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.customerCourseCount?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col className="mt-2" lg={12}>
              <Form.Label>Context</Form.Label>
              <Form.Control
                placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                as="textarea"
                type="text"
                {...register('context', {
                  minLength: {
                    value: 3,
                    message: 'Ce champ doit contenir au moins 3 caractères.',
                  },
                  maxLength: {
                    value: 2000,
                    message: 'Ce champ doit contenir au plus 255 caractères.',
                  },
                })}
                style={{ minHeight: 100 }}
              />
            </Col>
            <Col className="mt-2" lg={12}>
              <Form.Label>Context (EN)</Form.Label>
              <Form.Control
                placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                as="textarea"
                type="text"
                {...register('contextEn', {
                  minLength: {
                    value: 3,
                    message: 'Ce champ doit contenir au moins 3 caractères.',
                  },
                  maxLength: {
                    value: 2000,
                    message: 'Ce champ doit contenir au plus 255 caractères.',
                  },
                })}
                style={{ minHeight: 100 }}
              />
            </Col>
            <Col className="mt-2">
              <Form.Label>Description</Form.Label>
              <Form.Control
                placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                as="textarea"
                type="text"
                {...register('description', {
                  minLength: {
                    value: 3,
                    message: 'Ce champ doit contenir au moins 3 caractères.',
                  },
                  maxLength: {
                    value: 2000,
                    message: 'Ce champ doit contenir au plus 255 caractères.',
                  },
                })}
                style={{ minHeight: 100 }}
              />
            </Col>
            <Col className="mt-2" lg={12}>
              <Form.Label>Description (EN)</Form.Label>
              <Form.Control
                placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                as="textarea"
                type="text"
                {...register('descriptionEn', {
                  minLength: {
                    value: 3,
                    message: 'Ce champ doit contenir au moins 3 caractères.',
                  },
                  maxLength: {
                    value: 2000,
                    message: 'Ce champ doit contenir au plus 255 caractères.',
                  },
                })}
                style={{ minHeight: 100 }}
              />
            </Col>
          </Row>
          <Button
            type="submit"
            variant="success"
            disabled={isLoading}
            className="mt-3"
          >
            Confirmer
          </Button>
        </Card.Body>
      </Card>
    </Form>
  );
};

export default UpdateGovernanceCard;
