import ErrorComponent from '@/components/common/ErrorComponent';
import Loader from '@/components/common/Loader';
import SoftBadge from '@/components/common/SoftBadge';
import PageHeader from '@/components/common/header/PageHeader';
import {
  getColorFromCriteriaType,
  getColorFromIsArchived,
  getColorFromUserType,
  getReelArchivedName,
  getReelCriteriaType,
  getReelUserTypeName,
} from '@/helpers/UserHelpers';
import { ICardDetailsProps } from '@/interfaces/Card';
import IEarnPoint from '@/interfaces/EarnPoint';
import { useGetEarnPointQuery } from '@/services/earnPoints';
import { DateTime } from 'luxon';
import { FC, useEffect } from 'react';
import { Card, Col, Row } from 'react-bootstrap';

const EarnPointsCriteriaDetailsCard: FC<ICardDetailsProps<IEarnPoint>> = ({
  id,
  callBack,
}) => {
  const { data, isLoading, isError, error } = useGetEarnPointQuery(id);

  useEffect(() => {
    data && callBack?.(data);
  }, [data]);

  if (isLoading) {
    return <Loader absolute />;
  } else if (isError) {
    return <ErrorComponent error={error} absolute returnButton />;
  } else if (!data) {
    return (
      <ErrorComponent error={{ status: 404, data: {} }} absolute returnButton />
    );
  }

  return (
    <Row className="g-2">
      <Col xs={12}>
        {/* Earn Point Criteria */}
        <PageHeader title={`EarnPoints Criteria Details: ${data.title} / ${data.titleEn} (EN) `}>
          <p className="fs--1 mt-1">
            {DateTime.fromISO(data?.earnPointCriteria.createdAt).toFormat(
              "dd LLL yyyy 'at' HH:mm"
            )}
          </p>
          <div>
            <strong className="me-2">Status : </strong>
            <SoftBadge
              bg={getColorFromIsArchived(data.isArchived)}
              className="fs--2"
            >
              {getReelArchivedName(data.isArchived)}
            </SoftBadge>
          </div>
        </PageHeader>
        <Card className="mb-3 mt-3">
          <Card.Body>
            <Row>
              <Col md={6} lg={6} className="mb-4 mb-lg-0">
                <div className="mt-2">
                  <h6 className="mb-2">
                    Type :{' '}
                    <SoftBadge
                      bg={getColorFromCriteriaType(data.earnPointCriteria.type)}
                      className={'text-xl-center ms-2'}
                    >
                      {getReelCriteriaType(data.earnPointCriteria.type)}
                    </SoftBadge>{' '}
                  </h6>
                </div>
                {data.earnPointCriteria.userType !== null && (
                  <div className="mt-2">
                    <h6 className="mb-2">
                      User Type :{' '}
                      <SoftBadge
                        bg={getColorFromUserType(
                          data.earnPointCriteria.userType
                        )}
                        className={'text-xl-center ms-2'}
                      >
                        {getReelUserTypeName(data.earnPointCriteria.userType)}
                      </SoftBadge>
                    </h6>
                  </div>
                )}

                {data.earnPointCriteria.value !== null && (
                  <div className="mt-2">
                    <h6 className="mb-2">
                      Value :{' '}
                      <span className="ms-2">
                        {data.earnPointCriteria.value}
                      </span>
                    </h6>
                  </div>
                )}
              </Col>
            </Row>
          </Card.Body>
        </Card>
        {/* End of earn point criteria */}
      </Col>
    </Row>
  );
};

export default EarnPointsCriteriaDetailsCard;
