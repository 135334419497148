import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import IconButton from '@/components/common/IconButton';
import { useNavigate, useParams } from 'react-router-dom';
import Flex from '@/components/common/Flex';
import {
  useGetClientCompanyQuery,
  useUpdateClientCompanyMutation,
} from '@/services/clientCompany';
import { Controller, SubmitErrorHandler, useForm } from 'react-hook-form';
import { IClientCompanyCreate } from '@/interfaces/ClientCompany';
import useLoadOptions from '@/hooks/useLoadOptions';
import Loader from '@/components/common/Loader';
import {
  AsyncSelectComponent,
  CustomGoogleMenu,
} from '@/components/common/Select';
import classNames from 'classnames';
import { useEffect } from 'react';

const ClientCompanyEditView = () => {
  const navigation = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { data, isLoading } = useGetClientCompanyQuery(id!, { skip: !id });

  const {
    control,
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<DeepPartial<IClientCompanyCreate>>();

  const { loadPlacesAutocomplete } = useLoadOptions();

  const [updateCompanyMutation] = useUpdateClientCompanyMutation();

  const onSubmit = async (data: DeepPartial<IClientCompanyCreate>) => {
    await updateCompanyMutation({
      id: id!,
      body: {
        ...data,
        address: data.address?.placeId as any,
      },
    }).unwrap();
    navigation(-1);
  };

  const onError: SubmitErrorHandler<
    DeepPartial<IClientCompanyCreate>
  > = error => console.log(error);

  useEffect(() => {
    if (data) {
      reset({
        name: data.name,
        siren: data.siren,
        address: {
          place_id: data.address?.placeId,
          name: data.address?.name,
        },
      });
    }
  }, [data, reset]);

  if (isLoading) {
    return <Loader absolute />;
  }

  return (
    <>
      <Card className="mb-3">
        <Card.Header className="d-flex flex-between-center">
          <IconButton
            onClick={() => navigation(-1)}
            variant="nolina-default"
            size="sm"
            icon={faArrowLeft}
          />
          <Flex>
            <div
              className="bg-300 mx-3 d-xl-none"
              style={{ width: '1px', height: '29px' }}
            ></div>
          </Flex>
        </Card.Header>
      </Card>

      <Form onSubmit={handleSubmit(onSubmit, onError)}>
        <Card>
          <Card.Body className="bg-light">
            <h4 className="mb-0">Edit a client company</h4>
            <h6 className="mt-1 text-secondary">
              The required fields are marked with{' '}
              <span className="text-danger">*</span>.
            </h6>
            <Row className="gx-3">
              <Col>
                <Row className="gx-3">
                  <Col lg={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        Name <span className="text-danger">*</span> :
                      </Form.Label>
                      <Form.Control
                        placeholder="ex : NOEL10"
                        type="text"
                        {...register('name', {
                          required: 'This field is required.',
                          minLength: {
                            value: 2,
                            message:
                              'This field must contain at least 2 characters.',
                          },
                          maxLength: {
                            value: 255,
                            message:
                              'This field must contain at most 255 characters.',
                          },
                        })}
                        isInvalid={!!errors.name}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.name?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>

                  <Col lg={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        Adresse<span className="text-danger">*</span> :
                      </Form.Label>
                      <Controller
                        control={control}
                        name="address"
                        rules={{
                          required: 'Address is required',
                        }}
                        render={({ field }) => (
                          <AsyncSelectComponent
                            loadOptions={loadPlacesAutocomplete}
                            value={field.value}
                            closeMenuOnSelect
                            isClearable
                            onChange={field.onChange}
                            components={{
                              Menu: CustomGoogleMenu,
                            }}
                            getOptionLabel={option => option.name}
                            getOptionValue={option => option.place_id}
                          />
                        )}
                      />
                      <Form.Control.Feedback
                        type="invalid"
                        className={classNames({
                          'd-block': errors.address,
                        })}
                      >
                        {errors.address?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>

                  <Col lg={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        SIREN <span className="text-danger">*</span> :
                      </Form.Label>
                      <Form.Control
                        placeholder="ex : SIREN Number"
                        type="text"
                        {...register('siren', {
                          required: 'This field is required.',
                          minLength: {
                            value: 2,
                            message:
                              'The tax ID must contain at least 2 characters.',
                          },
                          maxLength: {
                            value: 15,
                            message:
                              'The tax ID must contain at most 15 characters.',
                          },
                        })}
                        isInvalid={!!errors.siren}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.siren?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>

                <Button
                  type="submit"
                  variant="success"
                  disabled={isLoading}
                  className="mt-3"
                >
                  Update the client company
                </Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Form>
    </>
  );
};

export default ClientCompanyEditView;
