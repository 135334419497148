import { PlaceAutocompleteResult } from './PlaceAutocomplete';
import { ISelectOption } from './Select';

export interface IWeeklyEvent {
  id: string;
  isArchived: boolean;
  name: string;
  startDate: string;
  endDate: string;
  type: string;
  nbOfAttendees: number;
  status: string;
  indicator: string;
  address: {
    id: string;
    placeId: string;
    address: string;
    name: string;
  };
}

export interface IWeeklyEventCreate
  extends Omit<IWeeklyEvent, 'id' | 'address' | 'type' | 'status' | 'indicator'> {
  address: PlaceAutocompleteResult | null;
  type: ISelectOption<string>;
  status: ISelectOption<string>;
  indicator: ISelectOption<string>;
}

export const STATUS_EVENT_OPTIONS: ISelectOption<string>[] = [
  {
    label: 'Active',
    value: 'active',
  },
  {
    label: 'Archived',
    value: 'archived',
  },
];

export const INDICATOR_EVENT_OPTIONS: ISelectOption<string>[] = [
  {
    label: 'Public',
    value: 'public',
  },
  {
    label: 'Private',
    value: 'private',
  },
];

export const EVENT_TYPE_OPTIONS: ISelectOption<string>[] = [
  {
    label: 'Event',
    value: 'event',
  },
  {
    label: 'Event Comin',
    value: 'event_comin',
  },
  {
    label: 'Concert',
    value: 'concert',
  },
  {
    label: 'Festival',
    value: 'festival',
  },
  {
    label: 'Sport',
    value: 'sport',
  },
  {
    label: 'Night Club',
    value: 'night_club',
  },
];