import { CookiesProvider } from 'react-cookie';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import './styles/scss/theme.scss';
import './styles/scss/google-maps.scss';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-datepicker/dist/react-datepicker.css';
import { TemplateProvider } from './hooks/useTemplate';
import AuthMiddleware from './middlewares/AuthMiddleware';
import GuestMiddleware from './middlewares/GuestMiddleware';
import HomeView from './pages';
import reportWebVitals from './reportWebVitals';
import { store } from './store';
import LoginView from './pages/auth/login';
import AuthView from './pages/auth';
import UsersView from './pages/users';
import PriceControllersView from './pages/priceControllers';
import SimulatePriceView from './pages/simulatePrice';
import RequestsView from './pages/searches';
import RequestView from './pages/searches/[id]';
import UserView from './pages/users/[id]';
import GovernancesView from './pages/governances';
import DriversView from './pages/drivers';
import DriverView from './pages/drivers/[id]';
import CouponsView from './pages/coupons';
import VehiclesView from './pages/vehicles';
import RewardsView from './pages/rewards';
import EarnPointsView from './pages/earnPoints';
import AssociationsView from './pages/associations';
import UserEditView from './pages/users/[id]/edit';
import VehicleEditView from './pages/vehicles/[id]/edit';
import DriverVehicleView from './pages/drivers/[id]/[vehicleId]';
import CouponView from './pages/coupons/[id]';
import CouponEditView from './pages/coupons/[id]/edit';
import RewardView from './pages/rewards/[id]';
import RewardEditView from './pages/rewards/[id]/edit';
import EarnPointView from './pages/earnPoints/[id]';
import EarnPointEditView from './pages/earnPoints/[id]/edit';
import GovernanceEditView from './pages/governances/[id]/edit';
import GovernanceView from './pages/governances/[id]';
// import CompanyEditView from "./pages/companies/[id]/edit";
import PartnershipsView from './pages/partnerships';
import PartnershipEditView from './pages/partnerships/[id]/edit';
import PartnershipView from './pages/partnerships/[id]';
import PartnershipReferralCodeEditView from './pages/partnerships/[id]/referralCodes/[id]/edit';
import OfferEditView from './pages/categories/[id]/edit';
import CompaniesView from './pages/companies';
import CompanyView from './pages/companies/[id]';
import CompanyEditView from './pages/companies/[id]/edit';
import DriverEditView from './pages/drivers/[id]/edit';
import PhoneOtpsView from './pages/phoneOtps';
import EmailOtpsView from './pages/emailOtps';
import DrivingPreferencesView from './pages/drivingPreferences';
import DrivingPreferenceEditView from './pages/drivingPreferences/[id]/edit';
import AssociationEditView from './pages/associations/[id]/edit';
import AssociationView from './pages/associations/[id]';
import DayAndTimeFactorView from './pages/dayAndTimeFactor';
import ArticlesView from './pages/articles';
import ArticleEditView from './pages/articles/[id]/edit';
import ArticleView from './pages/articles/[id]';
import LiveActivityMap from './pages/liveActivityMap';
import RideView from './pages/requests/[id]';
//import RidesView from './pages/rides';
import RidesView from './pages/requests';
import DriverClubsView from './pages/driverClubs';
import ImportDriversPage from './pages/drivers/import';
import RewardCoupon from './pages/coupons/rewards';
import { DriverClubEditView } from './pages/driverClubs/edit';
import { UserList } from './pages/users/userList';
import ActivityAreaPick from './pages/ActivityAreaPick';
import ActivityAreaPickNew from './pages/ActivityAreaPick/new';
import { UserToUserReferral } from './pages/userToUserReferral';
import EditActivityAreaPickView from './pages/ActivityAreaPick/[id]/edit';
import SupplyHours from './pages/supplyHours';
import FeatureTriggerView from './pages/featureTrigger';
import WeeklyChallengesView from './pages/weeklyChallenge';
import WeeklyChallengeView from './pages/weeklyChallenge/[id]';
import WeeklyChallengeEditView from './pages/weeklyChallenge/[id]/edit';
import WaMessagesView from './pages/waMessages';
import WaMessagesEditView from './pages/waMessages/[id]/edit';
import SettingsPage from './pages/settings';
import BookingView from './pages/bookings';
import BookingPriceControllerView from './pages/bookings/price';
import EditBookingView from './pages/bookings/[id]/edit';
import BookingDetailView from './pages/bookings/[id]';
import CategoriesView from './pages/categories';
import OffersView from './pages/offers(driver-ride-request)';
import ClientCompanyView from './pages/clientCompany';
import ClientCompanyEditView from './pages/clientCompany/[id]/edit';
import WeeklyEventsView from './pages/weeklyEvents';
import WeeklyEventEditView from './pages/weeklyEvents/[id]/edit';

const router = createBrowserRouter([
  {
    path: '/auth',
    element: <GuestMiddleware />,
    children: [
      {
        index: true,
        element: <AuthView />,
      },
      {
        path: 'login/:email',
        element: <LoginView />,
      },
    ],
  },
  {
    path: '*',
    element: <AuthMiddleware />,
    children: [
      {
        index: true,
        element: <HomeView />,
      },
      {
        path: 'associations',
        element: <AssociationsView />,
      },
      {
        path: 'liveActivityMap',
        element: <LiveActivityMap />,
      },
      {
        path: 'weeklyChallenges',
        element: <WeeklyChallengesView />,
      },
      {
        path: 'weeklyChallenges/:id',
        element: <WeeklyChallengeView />,
      },
      {
        path: 'weeklyChallenges/:id/edit',
        element: <WeeklyChallengeEditView />,
      },
      {
        path: 'featuresTrigger',
        element: <FeatureTriggerView />,
      },
      {
        path: 'activityAreaPick',
        element: <ActivityAreaPick />,
      },
      {
        path: 'activityAreaPick/new',
        element: <ActivityAreaPickNew />,
      },
      {
        path: 'activityAreaPick/:id',
        element: <EditActivityAreaPickView />,
      },
      {
        path: 'drivingPreferences',
        element: <DrivingPreferencesView />,
      },
      {
        path: 'drivingPreferences/:id/edit',
        element: <DrivingPreferenceEditView />,
      },
      {
        path: 'associations/:id/edit',
        element: <AssociationEditView />,
      },
      {
        path: 'associations/:id/',
        element: <AssociationView />,
      },
      {
        path: 'companies',
        element: <CompaniesView />,
      },
      {
        path: 'companies/:id',
        element: <CompanyView />,
      },
      {
        path: 'phone-otps',
        element: <PhoneOtpsView />,
      },
      {
        path: 'companies/:id/edit',
        element: <CompanyEditView />,
      },
      {
        path: 'associations',
        element: <AssociationsView />,
      },
      {
        path: 'email-otps',
        element: <EmailOtpsView />,
      },
      {
        path: 'categories',
        element: <CategoriesView />,
      },
      {
        path: 'offers-driver-ride-request',
        element: <OffersView />,
      },
      {
        path: 'categories/:id/edit',
        element: <OfferEditView />,
      },
      {
        path: 'earnPoints',
        element: <EarnPointsView />,
      },
      {
        path: 'earnPoints/:id',
        element: <EarnPointView />,
      },
      {
        path: 'earnPoints/:id/edit',
        element: <EarnPointEditView />,
      },
      {
        path: 'users',
        element: <UsersView />,
      },
      {
        path: 'users/:id',
        element: <UserView />,
      },
      {
        path: 'users/list',
        element: <UserList />,
      },
      {
        path: 'users/:id/edit',
        element: <UserEditView />,
      },
      {
        path: 'coupons',
        element: <CouponsView />,
      },
      {
        path: 'couponsReward',
        element: <RewardCoupon />,
      },
      {
        path: 'coupons/:id',
        element: <CouponView />,
      },
      {
        path: 'rides',
        element: <RidesView />,
      },
      {
        path: 'rides/:id',
        element: <RideView />,
      },
      {
        path: 'coupons/:id/edit',
        element: <CouponEditView />,
      },
      {
        path: 'governances',
        element: <GovernancesView />,
      },
      {
        path: 'governances/:id/edit',
        element: <GovernanceEditView />,
      },
      {
        path: 'governances/:id',
        element: <GovernanceView />,
      },
      {
        path: 'vehicles',
        element: <VehiclesView />,
      },
      {
        path: 'vehicles/:id/edit',
        element: <VehicleEditView />,
      },
      {
        path: 'rewards',
        element: <RewardsView />,
      },
      {
        path: 'rewards/:id',
        element: <RewardView />,
      },
      {
        path: 'rewards/:id/edit',
        element: <RewardEditView />,
      },
      {
        path: 'partnerships',
        element: <PartnershipsView />,
      },
      {
        path: 'partnerships/:id/edit',
        element: <PartnershipEditView />,
      },
      {
        path: 'partnerships/:id',
        element: <PartnershipView />,
      },
      {
        path: 'partnerships/:id/referralCodes/:referralCodesId/edit',
        element: <PartnershipReferralCodeEditView />,
      },
      {
        path: 'drivers',
        element: <DriversView />,
      },
      {
        path: 'drivers/:id',
        element: <DriverView />,
      },
      {
        path: 'drivers/:id/edit',
        element: <DriverEditView />,
      },
      {
        path: 'drivers/:id/vehicles/:vehicleId',
        element: <DriverVehicleView />,
      },
      {
        path: 'drivers/import',
        element: <ImportDriversPage />,
      },
      {
        path: 'priceControllers',
        element: <PriceControllersView />,
      },
      {
        path: 'dayAndTimeFactor',
        element: <DayAndTimeFactorView />,
      },
      {
        path: 'simulatePrice',
        element: <SimulatePriceView />,
      },
      {
        path: 'requests',
        element: <RequestsView />,
      },
      {
        path: 'requests/:id',
        element: <RequestView />,
      },
      {
        path: 'articles',
        element: <ArticlesView />,
      },
      {
        path: 'articles/:id',
        element: <ArticleView />,
      },
      {
        path: 'articles/:id/edit',
        element: <ArticleEditView />,
      },
      {
        path: 'driverClubs',
        element: <DriverClubsView />,
      },
      {
        path: 'driverClubs/edit',
        element: <DriverClubEditView />,
      },
      {
        path: 'userToUserReferal',
        element: <UserToUserReferral />,
      },
      {
        path: 'supplyhours',
        element: <SupplyHours />,
      },
      {
        path: 'waMessages',
        element: <WaMessagesView />,
      },
      {
        path: 'waMessages/:id/edit',
        element: <WaMessagesEditView />,
      },
      {
        path: 'settings',
        element: <SettingsPage />,
      },
      {
        path: 'bookings',
        element: <BookingView />,
      },
      {
        path: 'bookings/:id/edit',
        element: <EditBookingView />,
      },
      {
        path: 'bookings/:id',
        element: <BookingDetailView />,
      },
      {
        path: 'bookingsPriceController',
        element: <BookingPriceControllerView />,
      },
      {
        path: 'clientCompany',
        element: <ClientCompanyView />,
      },
      {
        path: 'clientCompany/:id/edit',
        element: <ClientCompanyEditView />,
      },
      {
        path: 'weeklyEvents',
        element: <WeeklyEventsView />,
      },
      {
        path: 'weeklyEvents/:id/edit',
        element: <WeeklyEventEditView />,
      }
    ],
  },
]);

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <Provider store={store}>
    <TemplateProvider>
      <CookiesProvider>
        <RouterProvider router={router} />
        <ToastContainer position="top-center" />
      </CookiesProvider>
    </TemplateProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
