import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { FC, useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Form, InputGroup, Row } from 'react-bootstrap';
import {
  Controller,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { ToastContentProps, toast } from 'react-toastify';
import Loader from '@/components/common/Loader';
import Select, { SelectPaginateAsync } from '@/components/common/Select';
import { IRewardForm, REWARD_TYPES, USER_TYPES } from '@/interfaces/Reward';
import { useEditRewardMutation, useGetRewardQuery } from '@/services/rewards';
import CardHeader from '@/components/card-headers/CardHeader';
import SoftBadge from '@/components/common/SoftBadge';
import {
  getColorFromIsArchived,
  getReelArchivedName,
} from '@/helpers/UserHelpers';
import useLoadOptions from '@/hooks/useLoadOptions';
import { IFilters } from '@/interfaces/Fetch';

interface UpdateRewardCardProps {
  id: string;
}

const UpdateRewardCard: FC<UpdateRewardCardProps> = ({ id }) => {
  const { data, isLoading } = useGetRewardQuery(id);
  const {
    control,
    register,
    reset,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<IRewardForm>();

  useEffect(() => {
    if (data) {
      reset({
        ...data,
        type: REWARD_TYPES.find(element => element.value === data.type),
        userType: USER_TYPES.find(element => element.value === data.userType),
        partnership: data.partnership,
      });
      setIsPartnership(!!data.partnership);
    }
  }, [data]);

  const [editRewardMutation] = useEditRewardMutation();

  const navigation = useNavigate();

  const [isArchived, setIsArchived] = useState<boolean>(
    data?.isArchived || false
  );

  const [isPartnership, setIsPartnership] = useState(false);

  const onSubmit: SubmitHandler<IRewardForm> = ({
    type,
    userType,
    partnership,
    ...data
  }) => {
    toast.promise(
      editRewardMutation([
        id,
        {
          ...data,
          type: type?.value,
          userType: userType?.value,
          partnershipId: partnership?.id,
        },
      ]).unwrap(),
      {
        pending: `Modification du reward en cours...`,
        success: {
          render() {
            navigation(-1);

            return (
              <p style={{ marginBottom: 0, textAlign: 'center' }}>
                {`Le reward à bien été modifié 🤩`}
              </p>
            );
          },
        },
        error: {
          render({ data }: ToastContentProps<FetchBaseQueryError>) {
            if (data?.status === 422) {
              return 'Les champs que vous avez remplis semblent être incorrects.';
            }
            return 'Une erreur est survenue';
          },
        },
      }
    );
  };

  const { loadPartnerships } = useLoadOptions();

  const defaultAdditionnal = useRef<IFilters>({
    pageIndex: 0,
    pageSize: 15,
  }).current;

  if (isLoading) {
    return <Loader absolute />;
  }

  const onError: SubmitErrorHandler<Partial<IRewardForm>> = error =>
    console.log(error);

  const handleArchiveToggle = async () => {
    try {
      setIsArchived(prevIsArchived => !prevIsArchived);
      await editRewardMutation([id, { isArchived: !isArchived }]);
      navigation(-1);
    } catch (error) {
      console.error('Error toggling archive status:', error);
    }
  };

  if (isLoading) {
    return <Loader absolute />;
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit, onError)}>
      <Card>
        <CardHeader title="Modification du reward" className="m-0" />
        <Card.Body className="bg-light">
          <Row className="gx-3">
            <Col lg={12}>
              <Form.Group className="mb-3">
                <Form.Label>Status :</Form.Label>
                <SoftBadge
                  bg={getColorFromIsArchived(data?.isArchived || false)}
                  className={'m-3 text-xl-center'}
                >
                  {getReelArchivedName(data?.isArchived || false)}
                </SoftBadge>
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>
                  Title <span className="text-danger">*</span> :
                </Form.Label>
                <Form.Control
                  placeholder="ex : Get 10% discount"
                  type="text"
                  {...register('title', {
                    required: 'Ce champ est requis.',
                    minLength: {
                      value: 2,
                      message: 'Ce champ doit contenir au moins 2 caractères.',
                    },
                    maxLength: {
                      value: 255,
                      message:
                        'Ce champ doit contenir au maximum 255 caractères.',
                    },
                  })}
                  isInvalid={!!errors.title}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.title?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>
                  Title (EN) <span className="text-danger">*</span> :
                </Form.Label>
                <Form.Control
                  placeholder="ex : Get 10% discount"
                  type="text"
                  {...register('titleEn', {
                    required: 'Ce champ est requis.',
                    minLength: {
                      value: 2,
                      message: 'Ce champ doit contenir au moins 2 caractères.',
                    },
                    maxLength: {
                      value: 255,
                      message:
                        'Ce champ doit contenir au maximum 255 caractères.',
                    },
                  })}
                  isInvalid={!!errors.titleEn}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.titleEn?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>
                  Reward Type <span className="text-danger">*</span> :
                </Form.Label>
                <Controller
                  control={control}
                  name="type"
                  render={({ field }) => (
                    <Select
                      options={REWARD_TYPES}
                      value={field.value}
                      onChange={value => {
                        field.onChange(value);
                        setIsPartnership(value?.value === 'partnership');
                      }}
                    />
                  )}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.type?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            {/* Partnership section */}

            {isPartnership && (
              <>
                <hr></hr>

                <Col className="mb-3 fw-bold" lg={12}>
                  Partnership Details
                </Col>

                <Col lg={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Partnership <span className="text-danger">*</span>
                    </Form.Label>
                    <Controller
                      control={control}
                      name="partnership"
                      render={({ field }) => (
                        <SelectPaginateAsync
                          loadOptions={loadPartnerships}
                          getOptionLabel={option => option.name}
                          getOptionValue={option => option.id}
                          value={field.value}
                          onChange={field.onChange}
                          additional={defaultAdditionnal}
                        />
                      )}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.partnership?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <hr></hr>
              </>
            )}

            {/* End of partnership section */}

            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>
                  User Type <span className="text-danger">*</span> :
                </Form.Label>
                <Controller
                  control={control}
                  name="userType"
                  render={({ field }) => (
                    <Select
                      options={USER_TYPES}
                      value={field.value}
                      onChange={field.onChange}
                    />
                  )}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.userType?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>
                  {watch('userType')?.value === 'user'
                    ? 'Discount Price'
                    : 'Discount Commission'}{' '}
                  &#40;%&#41;<span className="text-danger">*</span>:
                </Form.Label>
                <InputGroup>
                  <Form.Control
                    placeholder="ex : 10"
                    type="number"
                    {...register('discountAmount', {
                      required: 'Ce champ est requis.',
                      minLength: {
                        value: 1,
                        message:
                          'Le discount amount doit contenir au moins 2 caractères.',
                      },
                      maxLength: {
                        value: 255,
                        message:
                          'Le discount amount doit contenir au maximum 255 caractères.',
                      },
                    })}
                    isInvalid={!!errors.discountAmount}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.discountAmount?.message}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>
                  Max Discount Amount &#40;€&#41;{' '}
                  <span className="text-danger">*</span> :
                </Form.Label>
                <Form.Control
                  placeholder="ex : 10"
                  type="number"
                  {...register('maxDiscountAmount', {
                    required: 'Ce champ est requis..',
                  })}
                  isInvalid={!!errors.maxDiscountAmount}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.maxDiscountAmount?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>
                  Min Purchase Amount &#40;€&#41;{' '}
                  <span className="text-danger">*</span> :
                </Form.Label>
                <Form.Control
                  placeholder="ex : 10"
                  type="number"
                  {...register('minPurchaseAmount', {
                    required: 'Ce champ est requis..',
                  })}
                  isInvalid={!!errors.minPurchaseAmount}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.minPurchaseAmount?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>
                  Points <span className="text-danger">*</span> :
                </Form.Label>
                <Form.Control
                  placeholder="ex : 10"
                  type="number"
                  {...register('price', {
                    required: 'Ce champ est requis..',
                    minLength: {
                      value: 2,
                      message: 'Le price doit contenir au moins 2 caractères.',
                    },
                    maxLength: {
                      value: 255,
                      message:
                        'Le price doit contenir au maximum 255 caractères.',
                    },
                  })}
                  isInvalid={!!errors.price}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.price?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>
                  Limit Per User <span className="text-danger">*</span> :
                </Form.Label>
                <Form.Control
                  placeholder="ex : 10"
                  type="number"
                  {...register('limit', {
                    required: 'Ce champ est requis..',
                    minLength: {
                      value: 1,
                      message: 'Le limit doit contenir au moins 2 caractères.',
                    },
                    maxLength: {
                      value: 255,
                      message:
                        'Le limitdoit contenir au maximum 255 caractères.',
                    },
                  })}
                  isInvalid={!!errors.limit}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.limit?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col lg={12}>
              <Form.Group className="mb-3">
                <Form.Label>
                  Description Title <span className="text-danger">*</span> :
                </Form.Label>
                <Form.Control
                  placeholder="ex : Enjoy your reward"
                  type="text"
                  {...register('descriptionTitle', {
                    required: 'Ce champ est requis.',
                    minLength: {
                      value: 2,
                      message: 'Ce champ doit contenir au moins 2 caractères.',
                    },
                    maxLength: {
                      value: 255,
                      message:
                        'Ce champ doit contenir au maximum 255 caractères.',
                    },
                  })}
                  isInvalid={!!errors.descriptionTitle}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.descriptionTitle?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col lg={12}>
              <Form.Group className="mb-3">
                <Form.Label>
                  Description Title (EN) <span className="text-danger">*</span> :
                </Form.Label>
                <Form.Control
                  placeholder="ex : Enjoy your reward"
                  type="text"
                  {...register('descriptionTitleEn', {
                    required: 'Ce champ est requis.',
                    minLength: {
                      value: 2,
                      message: 'Ce champ doit contenir au moins 2 caractères.',
                    },
                    maxLength: {
                      value: 255,
                      message:
                        'Ce champ doit contenir au maximum 255 caractères.',
                    },
                  })}
                  isInvalid={!!errors.descriptionTitleEn}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.descriptionTitleEn?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col className="mt-2">
              <Form.Label>Description</Form.Label>
              <Form.Control
                placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                as="textarea"
                type="text"
                {...register('description', {
                  minLength: {
                    value: 3,
                    message: 'Ce champ doit contenir au moins 3 caractères.',
                  },
                  maxLength: {
                    value: 1000,
                    message: 'Ce champ doit contenir au plus 255 caractères.',
                  },
                })}
                style={{ minHeight: 100 }}
              />
            </Col>
            <Col className="mt-2" lg={12}>
              <Form.Label>Description (EN)</Form.Label>
              <Form.Control
                placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                as="textarea"
                type="text"
                {...register('descriptionEn', {
                  minLength: {
                    value: 3,
                    message: 'Ce champ doit contenir au moins 3 caractères.',
                  },
                  maxLength: {
                    value: 1000,
                    message: 'Ce champ doit contenir au plus 255 caractères.',
                  },
                })}
                style={{ minHeight: 100 }}
              />
            </Col>
          </Row>
          <Button
            type="submit"
            variant="success"
            disabled={isLoading}
            className="mt-3 me-2"
          >
            Confirmer
          </Button>
          <Button
            variant={isArchived ? 'info' : 'danger'}
            disabled={isLoading}
            onClick={handleArchiveToggle}
            className="mt-3"
          >
            {isArchived ? 'Unarchive' : 'Archive'}
          </Button>
        </Card.Body>
      </Card>
    </Form>
  );
};

export default UpdateRewardCard;
